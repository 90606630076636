import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BalanceTabHeaderIconComponent,
  CheckIconComponent,
  CopyIconVer2Component,
  OverviewTabHeaderIconComponent,
  ProfitAndLossTabHeaderIconComponent,
  RedirectIconVer2Component,
} from 'src/assets/icons/IconComponent';
import { WALLET_ANALYTICS_QUERY_PARAMS } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import { ellipseAddress } from 'src/helpers';
import { useCopy } from 'src/hooks/useCopy';
import './wallet-detail-content.scss';
import { REACT_APP_BLOCK_EXPLORER_URL } from 'src/web3/constants/envs';

export interface IWalletDetailContent {
  address: string;
}

const Content: React.FC<IWalletDetailContent> = ({ address }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { copy, copied } = useCopy();
  const account = useSelector((state: any) => state.currentAccount);
  const userTheme = useSelector((state: any) => state.theme);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const items = [
    {
      className: 'pb-0',
      icon: OverviewTabHeaderIconComponent,

      label: t('layout.header.walletDetail.walletOverview'),
      queryParam: WALLET_ANALYTICS_QUERY_PARAMS.OVERVIEW,
    },
    {
      className: 'pb-0',
      icon: BalanceTabHeaderIconComponent,
      label: t('layout.header.walletDetail.balance'),
      queryParam: WALLET_ANALYTICS_QUERY_PARAMS.BALANCES,
    },
    {
      className: '',
      icon: ProfitAndLossTabHeaderIconComponent,
      label: t('layout.header.walletDetail.profitAndLoss'),
      queryParam: WALLET_ANALYTICS_QUERY_PARAMS.PROFIT_AND_LOSS,
    },
  ];

  useEffect(() => {
    if (copied) return setIsOpen(true);
    return setIsOpen(false);
  }, [copied]);

  const renderContent = () => {
    return (
      <div className={`wallet-detail ${userTheme}`}>
        <div className="item-wrapper">
          <div className="wallet-detail__item justify-between">
            <span className="wallet-address">{ellipseAddress(account, 6, 4)}</span>
            <div className="icon-group">
              <Tooltip
                overlayClassName={`tooltip ${userTheme}`}
                title={t('layout.header.walletDetail.copyTooltip')}
                open={isOpen}
              >
                {copied ? (
                  <Icon className="wallet-detail-icon mr-10" component={CheckIconComponent} />
                ) : (
                  <Icon
                    className="wallet-detail-icon cursor-pointer mr-10"
                    component={CopyIconVer2Component}
                    onClick={() => copy(account)}
                  />
                )}
              </Tooltip>
              <Icon
                className="wallet-detail-icon cursor-pointer"
                component={RedirectIconVer2Component}
                onClick={() => window.open(`${REACT_APP_BLOCK_EXPLORER_URL}/address/${account}`)}
              />
            </div>
          </div>
        </div>

        <div className="line"></div>

        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={`item-wrapper ${item.className}`}
              onClick={() => history.push(`${PATHS.walletAnalytics()}/${item.queryParam}`)}
            >
              <div
                className={`wallet-detail__item justify-flex-start cursor-pointer ${
                  location.pathname.includes(PATHS.walletAnalytics()) &&
                  location.pathname.includes(item.queryParam) &&
                  'active'
                }`}
              >
                <Icon className="tab-icon wallet-detail-icon" component={item.icon} />
                <span>{item.label}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return <>{renderContent()}</>;
};

export default Content;
