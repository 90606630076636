export const DAY_FORMAT = 'MM/DD';
export const FULL_DATETIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const SECS_PER_MIN = 60;
export const MS_PER_SEC = 1000;

export enum PAIR_WITH_STABLE_COIN {
  price = 4,
  amount = 2,
  total = 4,
  fee = 4,
  balance = 7,
}

export enum PAIR_WITH_NON_STABLE_COIN {
  price = 3,
  amount = 2,
  total = 3,
  fee = 3,
  balance = 4,
}

export enum USER_ROLE {
  NORMAL = 1,
  MASTER_AFFILIATE = 2,
  AFFILIATE_SUB_1 = 3,
  AFFILIATE_SUB_2 = 4,
}
