export enum SocketEvent {
  StellarOrderbookUpdated = 'StellarOrderbookUpdated',
  OrdersUpdated = 'OrdersUpdated',
  OrderbookTradeCreated = 'OrderbookTradeCreated',
  PoolTradeCreated = 'PoolTradeCreated',
  SwapCreated = 'SwapCreated',
  TradingFeeUpdated = 'TradingFeeUpdated',
  TradesUpdated = 'TradesUpdated',
  Ticker24h = '24hTicker',
  Orders = 'orders',
  Trades_Updated = 'trades_updated',
  TradingFee = 'trading_fee',
  Trades_PairId = 'trades_',
  delete_pair = 'delete_pair',
  Notification = 'notification',
  CancelOrder = 'cancel_order',
}
