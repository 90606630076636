import { Dropdown } from 'antd';
import React, { useState } from 'react';
import Content from './Content';
import { IWalletDetailContent } from './Content';

interface IWalletDetail extends IWalletDetailContent {
  children: React.ReactNode;
  isDisable?: boolean;
}

const ProfileDetail: React.FC<IWalletDetail> = ({ children, isDisable = false, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      open={isOpen}
      trigger={['hover']}
      overlay={
        !isDisable ? (
          <Content {...props} isOpenDropdownWrapper={isOpen} onClick={() => setIsOpen(false)} />
        ) : (
          <></>
        )
      }
      placement="bottomRight"
      onOpenChange={(boolean: boolean) => {
        setIsOpen(boolean);
      }}
      overlayClassName="dropdown-wrapper"
    >
      {children}
    </Dropdown>
  );
};

export default ProfileDetail;
