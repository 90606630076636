import loadable, { DefaultComponent } from '@loadable/component';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import useWindowSize from 'src/hooks/useWindowSize';
import { ExchangePageLoading, MarketPageLoading, WalletAnalyticsLoading } from 'src/pages/loadings';
import { LoadingPage } from './components/LoadingPage';
import { PrivateRoute } from './components/PrivateRoute';

const LoadingByTemplate: React.FC = () => {
  const history = useHistory();

  if (history.location.pathname.includes(PATHS.exchange())) {
    return <ExchangePageLoading />;
  }
  if (history.location.pathname.includes(PATHS.marketOverview())) {
    return <MarketPageLoading />;
  }
  if (history.location.pathname.includes(PATHS.walletAnalytics())) {
    return <WalletAnalyticsLoading />;
  }
  if (history.location.pathname.includes(PATHS.myTier())) {
    return <MarketPageLoading />;
  }
  return <LoadingPage />;
};

function loadableWFallback(loadFn: (props: {}) => Promise<DefaultComponent<{}>>) {
  return loadable(loadFn, {
    fallback: <LoadingByTemplate />,
  });
}

const NotFound = loadableWFallback(() => import('./components/NotFound'));
const Exchange = loadableWFallback(() => import('src/pages/exchange'));
const WalletAnalytics = loadableWFallback(() => import('src/pages/wallet-analytics'));
const MyOrderStatistics = loadableWFallback(
  () => import('src/pages/wallet-analytics/components/02.tab-overview/MyOrderStatistics'),
);
const MarketOverview = loadableWFallback(() => import('src/pages/market-overview'));
const MyTier = loadableWFallback(() => import('src/pages/my-tier'));
const AllTier = loadableWFallback(() => import('src/pages/my-tier/components/5.all-tier'));

const Routes: React.FC = () => {
  const { isMobile } = useWindowSize();

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={PATHS.exchange()} />} />
      <Route exact path={PATHS.marketOverview()} component={MarketOverview} />
      <Route exact path={`${PATHS.exchange()}/:pairNameParam?`} component={Exchange} />
      <PrivateRoute
        exact
        path={`${PATHS.walletAnalytics()}/:tabName?`}
        component={WalletAnalytics}
      />
      <Route exact path={PATHS.myOrderStatistics()} component={MyOrderStatistics} />
      <PrivateRoute exact path={PATHS.myTier()} component={MyTier} />
      {isMobile && <PrivateRoute exact path={PATHS.allTier()} component={AllTier} />}
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
