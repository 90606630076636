import { get } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PATHS } from 'src/constants/paths';
import { setRoleAffiliate } from 'src/helpers/storage';
import { useLogin } from 'src/hooks/useLogin';
import { setIsWrongNetWork } from 'src/store/actions/network';
import {
  setAccessTokenRedux,
  setRoleAffiliateIAO,
  setStoreInfoUserIAO,
} from 'src/store/actions/user';
import { ConnectorKey } from '../connectors';
import { REACT_APP_CHAIN_ID } from '../constants/envs';
import { getStorageWallet } from '../constants/storages';
import { setupNetwork } from '../helpers/setupNetwork';
import { clearAllBalances } from './../../store/actions/balances';
import { useActiveWeb3React } from './useActiveWeb3React';
import { useConnectWallet } from './useConnectWallet';
import { useGetBalanceBep20 } from './useGetBalanceBep20';

/**
 * Use for network and injected - logs user in
 * and out after checking what network they're on
 */

export function useWalletListener() {
  const { error, activate, connector, deactivate, active, chainId } = useActiveWeb3React();
  const { userLogin } = useLogin();
  const history = useHistory();
  const { connectWallet } = useConnectWallet();
  const currentAccount = useSelector((state: any) => state.currentAccount);
  const networkIsWrong = useSelector((state: any) => state.networkIsWrong);
  const dispatch = useDispatch();
  const connectorKey = getStorageWallet();
  const { disconnectWallet } = useConnectWallet();

  const { quoteToken, baseToken } = useSelector((state: any) => ({
    quoteToken: get(state, 'pairs.currentPair.quote_bsc_address', ''),
    baseToken: get(state, 'pairs.currentPair.base_bsc_address', ''),
  }));
  const [refreshBalanceBaseToken] = useGetBalanceBep20(baseToken);
  const [refreshBalanceQuoteToken] = useGetBalanceBep20(quoteToken);

  const refreshBalanceToken = () => {
    refreshBalanceBaseToken();
    refreshBalanceQuoteToken();
  };

  useEffect(() => {
    if (active && chainId === Number(REACT_APP_CHAIN_ID)) {
      // connect correct network
      dispatch(setIsWrongNetWork(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, chainId]);

  const reconnectWallet = useCallback(async () => {
    if (connectorKey === ConnectorKey.walletConnect && !connector && !active) {
      await connectWallet(ConnectorKey.walletConnect);
    } else {
      await connectWallet(ConnectorKey.injected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connector, active]);

  const handleDisconnect = () => {
    disconnectWallet();
    dispatch(clearAllBalances());
    dispatch(setStoreInfoUserIAO(''));
    dispatch(setRoleAffiliateIAO(''));
    setRoleAffiliate('');
    history.push(PATHS.default());
  };

  useEffect(() => {
    reconnectWallet();
  }, [reconnectWallet]);

  useEffect(() => {
    const { ethereum } = window;
    if (!ethereum) {
      return;
    }

    const update = async (data: any) => {
      refreshBalanceToken();

      if (connectorKey === ConnectorKey.injected) {
        if (data?.chainId) {
          if (networkIsWrong) {
            dispatch(clearAllBalances());
            const result = await setupNetwork();
            if (result) {
              dispatch(setIsWrongNetWork(false));
            }
          }
        }

        if (data?.account && data?.account !== currentAccount) {
          setTimeout(async () => {
            await deactivate();
            await userLogin(connectorKey as any, data?.account);
          }, 500);
          dispatch(setAccessTokenRedux(''));
        }
      } else {
        if (data?.chainId) {
          if (networkIsWrong) {
            dispatch(clearAllBalances());
            const result = await setupNetwork();
            if (result) {
              dispatch(setIsWrongNetWork(false));
            }
          }
        }

        if (data?.account && data?.account !== currentAccount) {
          dispatch(setAccessTokenRedux(''));
          await userLogin(connectorKey as any, data?.account);
        }
      }
    };

    const handleDeactivate = () => {
      handleDisconnect();
    };

    const handleWeb3ReactError = (err: any) => {
      console.log('handleWeb3ReactError', { err });
    };

    window.ethereum.on('networkChanged', function (networkId: string) {
      update({ chainId: networkId });
    });

    if (connector && connector.on) {
      connector.on('Web3ReactUpdate', update);
      connector.on('Web3ReactDeactivate', handleDeactivate);
      connector.on('Web3ReactError', handleWeb3ReactError);
    }

    return () => {
      if (connector && connector.removeListener) {
        connector.removeListener('Web3ReactUpdate', update);
        connector.removeListener('Web3ReactDeactivate', handleDeactivate);
        connector.removeListener('Web3ReactError', handleWeb3ReactError);
      }
    };
    // eslint-disable-next-line
  }, [error, activate, deactivate, connector, currentAccount, chainId]);
}
