import { Web3Provider } from '@ethersproject/providers';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import axios, { AxiosError } from 'axios';
import { t } from 'i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fracToast2 } from 'src/components/07.toast';
import { WALLET_IS_ADMIN_ERROR_CODE } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import { setRoleAffiliate, setStorageJwtToken, userAddress } from 'src/helpers/storage';
import { AuthServices } from 'src/services/auth-service';
import { BaseSocket } from 'src/socket/BaseSocket';
import { setCurrentAccount } from 'src/store/actions/account';
import { setIsWrongNetWork } from 'src/store/actions/network';
import { setAccessTokenRedux, setStoreInfoUserIAO } from 'src/store/actions/user';
import {
  setBalanceAll,
  setBalanceAvailable,
  setBalanceInOrder,
} from 'src/store/actions/wallet-analytic';
import { WEB3_ERROR } from 'src/types';
import { ConnectorKey, connectors } from 'src/web3/connectors';
import { REACT_APP_MESSAGES_SIGN } from 'src/web3/constants/envs';
import { signMessage } from 'src/web3/helpers';
import { useConnectWallet } from 'src/web3/hooks';
import { setRoleAffiliateIAO } from './../store/actions/user';

export const IAOEndPoint = process.env.REACT_APP_API_ENDPOINT_IAO;

export const useLogin = () => {
  const dispatch = useDispatch();
  const { connectWallet, disconnectWallet } = useConnectWallet();
  const durationActive = moment().add(1, 'h').unix();
  const authService = new AuthServices();

  const getAccountConnected = async (
    connector: InjectedConnector | WalletConnectConnector,
    accountProps?: string,
  ) => {
    if (accountProps) {
      return accountProps;
    }
    const provider = await connector.getProvider();
    const signer = new Web3Provider(provider).getSigner();
    const account = await signer.getAddress();
    return account;
  };

  const getSignature = async (
    accountSelected: string,
    connector: InjectedConnector | WalletConnectConnector,
  ) => {
    const message = `${REACT_APP_MESSAGES_SIGN} ${accountSelected}@${durationActive}`;
    const provider = await connector.getProvider();
    const signer = new Web3Provider(provider).getSigner();
    const signature = await signMessage(signer, message);
    return {
      message,
      signature,
    };
  };

  const retrySocket = async () => {
    BaseSocket.getInstance().disconnectSocket();
    BaseSocket.getInstance().reconnect();
  };

  const resetStore = (accessToken: string, accountSelected: string) => {
    dispatch(setAccessTokenRedux(accessToken));
    dispatch(setCurrentAccount(accountSelected as string));
    dispatch(setBalanceAvailable([]));
    dispatch(setBalanceInOrder([]));
    dispatch(setBalanceAll([]));
    dispatch(setIsWrongNetWork(false));
  };

  const getUserInfoIAO = async (accessToken: string) => {
    const res = await axios.get(`${IAOEndPoint}/account`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
    dispatch(setStoreInfoUserIAO(res?.data?.data?.data));
    dispatch(setRoleAffiliateIAO(res?.data?.data?.data?.role));
    setRoleAffiliate(res?.data?.data?.data?.role);
  };

  const userLogin = async (connectorKey: ConnectorKey, account?: string) => {
    try {
      const connector = connectors[connectorKey];
      await connectWallet(connectorKey);
      const accountSelected = await getAccountConnected(connector, account);
      const { signature, message } = await getSignature(accountSelected, connector);
      const resIAO = await axios.post(`${IAOEndPoint}/user/auth/login`, {
        signature,
        signData: message,
        referral: localStorage.getItem('referralCode') ?? null,
      });
      const res = await authService.login({ message, signature });
      const { data } = res;
      const accessToken = data?.access_token;
      const accessTokenIAO = resIAO?.data?.data?.accessToken;
      await getUserInfoIAO(accessTokenIAO);
      resetStore(accessToken, accountSelected);
      setStorageJwtToken(accessToken, accessTokenIAO);
      retrySocket();
      localStorage.setItem(userAddress, accountSelected);
    } catch (error: any) {
      localStorage.removeItem(userAddress);
      console.log('logout');
      resetStore('', '');
      disconnectWallet();
      dispatch(setStoreInfoUserIAO(''));
      dispatch(setRoleAffiliateIAO(''));
      setRoleAffiliate('');
      if (error?.code === AxiosError.ERR_NETWORK) {
        toast.error(`${t(MESSAGES.MC28)}`);
        return;
      }

      const codeErr = error?.response?.data?.code;

      if (codeErr === WALLET_IS_ADMIN_ERROR_CODE) {
        fracToast2.error(`${t('dex.message.MC51')}`);
        return;
      }

      let baseError = {
        type: 'user_reject',
        message: error?.message,
        description: error,
      } as WEB3_ERROR;
      throw baseError;
    }
  };

  return { userLogin, getUserInfoIAO };
};
