import _ from 'lodash';
import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';
import ToastWrapper from './ToastContainer';
import ViewIcon from 'src/assets/icons/toast/view.svg';
import { useTranslation } from 'react-i18next';

enum TOAST_TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

enum TOAST_ORDER_TYPE {
  orderCompleted = 'orderCompleted',
}

export const fracToast = (() => {
  return toast;
})();

const ToastWrapperComponent: React.FC<{ tx: string }> = (props) => {
  const { t } = useTranslation();

  return (
    <ToastWrapper
      headContent={''}
      bodyContent={
        <div>
          {t(MESSAGES.MC47)} &nbsp;
          <a
            href={`${process.env.REACT_APP_BLOCK_EXPLORER_URL}/tx/${props.tx}`}
            target="_blank"
            rel="noreferrer"
          >
            <span>
              <img src={ViewIcon} alt="" />
              {t('dex.message.MC50')}
            </span>
          </a>
        </div>
      }
    />
  );
};

export const fracToast2 = (() => {
  const toastClone = _.cloneDeep(toast);

  const toastRender = (type: TOAST_TYPE) => {
    return (headContent?: React.ReactNode, bodyContent?: React.ReactNode) =>
      toast[type](<ToastWrapper headContent={headContent} bodyContent={bodyContent} />);
  };

  const toastOrderRender = () => {
    return (tx: string) => {
      return toast[TOAST_TYPE.SUCCESS](<ToastWrapperComponent tx={tx} />);
    };
  };

  return {
    ...toastClone,
    [TOAST_TYPE.INFO]: toastRender(TOAST_TYPE.INFO),
    [TOAST_TYPE.ERROR]: toastRender(TOAST_TYPE.ERROR),
    [TOAST_TYPE.WARNING]: toastRender(TOAST_TYPE.WARNING),
    [TOAST_TYPE.SUCCESS]: toastRender(TOAST_TYPE.SUCCESS),
    [TOAST_ORDER_TYPE.orderCompleted]: toastOrderRender(),
  };
})();
