import { ServiceBase } from './core/service-base';

export class TierService extends ServiceBase {
  getTierList = () => {
    return this.get(`/trading-level`);
  };
  getMyTierList = () => {
    return this.get(`/trading-level/my-tier`);
  };
}
