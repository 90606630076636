import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import axios from 'axios';
import { get } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { DarkModeIcon, LightModeIcon, NetworkIcon } from 'src/assets/icons';
import { ArrowRightLanguageIconComponent } from 'src/assets/icons/IconComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import ConnectWalletModal from 'src/components/06.modals/ConnectWalletModal';
import { USER_ROLE } from 'src/constants/formatters';
import { PATHS, PATHS_IAO } from 'src/constants/paths';
import { renderLang } from 'src/helpers';
import { setStorageTheme } from 'src/helpers/storage';
import { useAuth } from 'src/hooks/useAuth';
import { IAOEndPoint } from 'src/hooks/useLogin';
import { THEME_MODE } from 'src/interfaces/theme';
import { TierService } from 'src/services/tier-service';
import { setTheme } from 'src/store/actions/theme';
import { MyTier, pickTierColor } from '../constants';
import { languages, LANGUAGE_CODE } from '../ProfileDetail/MultiLanguageContent';
import AccountContent from './AccountContent';
import './mobile-menu-detail-content.scss';
import MultiLanguageContent from './MultiLanguageContent';
import WalletContent from './WalletContent';
import { REACT_APP_NETWORK_NAME } from 'src/web3/constants/envs';

interface IWalletDetail {
  address: string;
  onClickItem: () => void;
  isOpen: boolean;
}

const MobileMenuDetail: React.FC<IWalletDetail> = ({ address, onClickItem, isOpen }) => {
  const { t, i18n } = useTranslation();
  const { isAuth } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const networkIsWrong = useSelector((state: any) => state.networkIsWrong);
  const userTheme = useSelector((state: any) => state.theme);
  const account = useSelector((state: any) => state.currentAccount);

  const { roleAffiliate } = useSelector((state: any) => ({
    roleAffiliate: get(state, 'user.roleAffiliate', ''),
  }));

  const [language, setLanguage] = useState<any>(
    renderLang(i18n.resolvedLanguage) || LANGUAGE_CODE.ENGLISH,
  );

  const [isOpenMultiLanguage, setIsOpenMultiLanguage] = useState(false);
  const [isOpenConnectWalletModal, setIsOpenConnectWalletModal] = useState(false);
  const [isOpenWallet, setIsOpenWallet] = useState(false);
  const [isOpenAccount, setIsOpenAccount] = useState(false);
  const [myTier, setMyTier] = useState<MyTier>();

  const urlBecomeAffiliate = useRef<string>('');
  const fetchUrl = async () => {
    try {
      await axios.get(`${IAOEndPoint}/affiliate/setting`).then((res: any) => {
        urlBecomeAffiliate.current = res.data.data.registrationUrl;
      });
    } catch (error) {
      console.log('err', error);
    }
  };

  useEffect(() => {
    fetchUrl();
  }, []);

  const userLoginSuccess = useMemo(() => {
    return isAuth && !!account;
  }, [isAuth, account]);

  const handleChangeTheme = () => {
    if (userTheme === THEME_MODE.DARK) {
      dispatch(setTheme(THEME_MODE.LIGHT));
      setStorageTheme(THEME_MODE.LIGHT.toLocaleLowerCase());
    } else {
      dispatch(setTheme(THEME_MODE.DARK));
      setStorageTheme(THEME_MODE.DARK.toLocaleLowerCase());
    }
  };

  const handleToggleMultiLanguageContent = () => {
    setIsOpenMultiLanguage(!isOpenMultiLanguage);
  };

  const handleToggleWalletContent = () => {
    setIsOpenWallet(!isOpenWallet);
  };

  const handleToggleAccountContent = () => {
    setIsOpenAccount(!isOpenAccount);
  };

  const handleDisconnect = () => {
    setIsOpenAccount(false);
    onClickItem();
  };

  const fetchMyTierList = async () => {
    try {
      const instance = new TierService();
      const { data } = await instance.getMyTierList();
      setMyTier(data?.current_tier);
    } catch (error) {}
  };

  useEffect(() => {
    if (userLoginSuccess && account) {
      fetchMyTierList();
    }
  }, [userLoginSuccess, account]);

  useEffect(() => {
    if (!isOpen) {
      setIsOpenMultiLanguage(false);
      setIsOpenWallet(false);
      setIsOpenAccount(false);
    }
  }, [isOpen]);

  const ListMenuAndSub = useMemo(
    () => [
      {
        icon: null, // ExchangeIcon
        label: t('layout.header.menu.exchange'),
        className: 'exchange-icon',
        subLink: null,
        hasNewLink: false,
        children: [
          { label: t('layout.header.subMenu.marketOverview'), subLink: PATHS.marketOverview() },
          { label: t('layout.header.subMenu.trading'), subLink: PATHS.exchange() },
          // { label: t('layout.header.subMenu.myTier'), subLink: PATHS.myTier() },
        ],
        childrenSubLinks: [PATHS.marketOverview(), PATHS.exchange()],
        role: [
          USER_ROLE.NORMAL,
          USER_ROLE.AFFILIATE_SUB_1,
          USER_ROLE.AFFILIATE_SUB_2,
          USER_ROLE.MASTER_AFFILIATE,
        ],
      },
      {
        icon: null, // IAOIcon
        label: t('layout.header.menu.IAO'),
        className: 'iao-icon',
        subLink: PATHS_IAO.iao(), // TODO : replace link of IAO
        hasNewLink: false,
        isLinkIao: true,
        role: [
          USER_ROLE.NORMAL,
          USER_ROLE.AFFILIATE_SUB_1,
          USER_ROLE.AFFILIATE_SUB_2,
          USER_ROLE.MASTER_AFFILIATE,
        ],
      },
      {
        icon: null, // AffiliatesIcon
        label: t('layout.header.menu.affiliates'),
        className: 'affiliates-icon',
        subLink: PATHS_IAO.affiliates(),
        hasNewLink: false,
        isLinkIao: true,
        role: [USER_ROLE.AFFILIATE_SUB_1, USER_ROLE.AFFILIATE_SUB_2, USER_ROLE.MASTER_AFFILIATE],
      },
      // {
      //   icon: null, // StakeIcon
      //   label: t('layout.header.menu.stakeDAD'),
      //   className: 'stake-dad-icon',
      //   subLink: null,
      //   hasNewLink: false,
      //   isLinkIao: true,
      //   children: [
      //     { label: t('layout.header.menu.stake'), subLink: PATHS_IAO.stake() },
      //     { label: t('layout.header.menu.unStake'), subLink: PATHS_IAO.unStake() },
      //     { label: t('layout.header.menu.history'), subLink: PATHS_IAO.historyStake() },
      //   ],
      //   role: [
      //     USER_ROLE.NORMAL,
      //     USER_ROLE.AFFILIATE_SUB_1,
      //     USER_ROLE.AFFILIATE_SUB_2,
      //     USER_ROLE.MASTER_AFFILIATE,
      //   ],
      //   childrenSubLinks: [PATHS_IAO.stake(), PATHS_IAO.unStake(), PATHS_IAO.historyStake()],
      // },
      // {
      //   icon: null,
      //   label: t('layout.header.menu.becomeAffiliates'),
      //   className: '',
      //   subLink: urlBecomeAffiliate.current,
      //   hasNewLink: false,
      //   isLinkIao: true,
      //   role: [
      //     USER_ROLE.NORMAL,
      //     USER_ROLE.AFFILIATE_SUB_1,
      //     USER_ROLE.AFFILIATE_SUB_2,
      //     USER_ROLE.MASTER_AFFILIATE,
      //   ],
      // },
      // {
      //   icon: null,
      //   label: t('layout.header.menu.userGuides'),
      //   className: '',
      //   subLink: PATHS_IAO.userGuides(),
      //   hasNewLink: false,
      //   isLinkIao: true,
      //   role: [
      //     USER_ROLE.NORMAL,
      //     USER_ROLE.AFFILIATE_SUB_1,
      //     USER_ROLE.AFFILIATE_SUB_2,
      //     USER_ROLE.MASTER_AFFILIATE,
      //   ],
      // },
      // {
      //   icon: null,
      //   label: t('layout.header.menu.aboutUs'),
      //   className: '',
      //   subLink: PATHS.aboutUs(),
      //   hasNewLink: false,
      // },
      {
        icon: null,
        label: t('layout.header.menu.contactUs'),
        className: '',
        subLink: PATHS_IAO.aboutUs(),
        hasNewLink: false,
        isLinkIao: true,
        role: [
          USER_ROLE.NORMAL,
          USER_ROLE.AFFILIATE_SUB_1,
          USER_ROLE.AFFILIATE_SUB_2,
          USER_ROLE.MASTER_AFFILIATE,
        ],
      },
    ],
    [t],
  );

  const renderMenus = useMemo(() => {
    return ListMenuAndSub.filter((item: any) =>
      item?.role.includes(Number(roleAffiliate) || USER_ROLE.NORMAL),
    )?.map((item: any, index) => {
      return !item.children
        ? {
            key: index.toString(),
            label: item?.isLinkIao ? (
              <a href={item.subLink} target={item.hasNewLink ? '_blank' : ''} rel="noreferrer">
                {item.label}
              </a>
            ) : (
              <Link
                to={item.hasNewLink ? { pathname: item.subLink } : item.subLink}
                target={item.hasNewLink ? '_blank' : ''}
                onClick={onClickItem}
              >
                {item.label}
              </Link>
            ),
          }
        : {
            key: index.toString(),
            label: item.label,
            children: item.children?.map((subItem: any, subIndex: any) => {
              return {
                key: `${index.toString()}.${subIndex.toString()}`,
                label: item?.isLinkIao ? (
                  <a
                    href={subItem.subLink}
                    target={item.hasNewLink ? '_blank' : ''}
                    rel="noreferrer"
                  >
                    {subItem.label}
                  </a>
                ) : (
                  <Link to={subItem.subLink} onClick={onClickItem}>
                    {subItem.label}
                  </Link>
                ),
              };
            }),
          };
    });
  }, [ListMenuAndSub, onClickItem, roleAffiliate]);

  if (isOpenMultiLanguage) {
    return (
      <MultiLanguageContent
        language={language}
        onSelectLanguage={setLanguage}
        onClose={handleToggleMultiLanguageContent}
      />
    );
  }

  if (isOpenWallet) {
    return (
      <WalletContent
        address={address}
        onClose={handleToggleWalletContent}
        onClickItem={() => {
          onClickItem();
          setIsOpenWallet(false);
        }}
      />
    );
  }

  if (isOpenAccount) {
    return <AccountContent onClose={handleToggleAccountContent} onDisconnect={handleDisconnect} />;
  }

  return (
    <div className={`mobile-menu-detail ${userTheme}`}>
      <Menu mode="inline" items={renderMenus} />

      <div className="line"></div>

      <div className="mobile-menu-detail__item-wrapper">
        <div
          className="mobile-menu-detail__item-wrapper__item justify-between"
          onClick={handleToggleMultiLanguageContent}
        >
          <span>
            {t('layout.header.profileDetail.language')}: {languages[language]?.label}
          </span>
          <Icon className="" component={ArrowRightLanguageIconComponent} />
        </div>
      </div>

      <div className="mobile-menu-detail__item-wrapper mb-26">
        <div className="mobile-menu-detail__item-wrapper__item justify-between">
          <div>
            <span>{t('layout.header.profileDetail.appearance')}</span>
          </div>
          <div className="dark-light-mode">
            <span>
              {userTheme === THEME_MODE.DARK
                ? t('layout.header.profileDetail.darkMode')
                : t('layout.header.profileDetail.lightMode')}
            </span>
            <img
              src={userTheme === THEME_MODE.DARK ? DarkModeIcon : LightModeIcon}
              alt="icon"
              onClick={handleChangeTheme}
            />
          </div>
        </div>
      </div>

      <div className="line"></div>

      <div className="mobile-menu-detail__item-wrapper">
        <div className="mobile-menu-detail__item-wrapper__item">
          <div className={`network ${networkIsWrong ? 'warning-network' : ''}`}>
            <img src={NetworkIcon} alt="icon" />
            <span>{networkIsWrong ? t('layout.header.menu.wrongNetwork') : REACT_APP_NETWORK_NAME}</span>
          </div>
        </div>
      </div>

      {(!isAuth || !account) && (
        <div className="mobile-menu-detail__item-wrapper">
          <div className="mobile-menu-detail__item-wrapper__item">
            <ButtonOutLined
              className="button-connect"
              onClick={() => {
                setIsOpenConnectWalletModal(true);
              }}
            >
              {t('layout.header.menu.connectWallet')}
            </ButtonOutLined>
          </div>
        </div>
      )}

      {isAuth && account && (
        <>
          <div className="mobile-menu-detail__item-wrapper">
            <div
              className="mobile-menu-detail__item-wrapper__item justify-between"
              onClick={handleToggleWalletContent}
            >
              <div>
                <span>{t('mobile.menu.wallet')}</span>
              </div>
              <Icon className="" component={ArrowRightLanguageIconComponent} />
            </div>
          </div>

          <div className="mobile-menu-detail__item-wrapper">
            <div
              className="mobile-menu-detail__item-wrapper__item justify-between"
              onClick={handleToggleAccountContent}
            >
              <div>
                <span>{t('mobile.menu.account')}</span>
              </div>
              <Icon className="" component={ArrowRightLanguageIconComponent} />
            </div>
          </div>

          {/* {myTier?.tier && (
            <div className="mobile-menu-detail__item-wrapper">
              <div
                className="mobile-menu-detail__item-wrapper__item justify-between"
                onClick={() => {
                  location.pathname !== PATHS.myTier() && history.push(PATHS.myTier());
                  onClickItem();
                }}
              >
                <div className="my-tier-mobile-title">
                  <span>{t('dex.myTier.tier')}: </span>
                  <div
                    className="my-tier-mobile-title__my-tier-title"
                    style={{ color: pickTierColor(myTier.id, userTheme === THEME_MODE.DARK) }}
                  >
                    {myTier.tier}
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </>
      )}

      <ConnectWalletModal
        open={isOpenConnectWalletModal}
        onCancel={() => setIsOpenConnectWalletModal(false)}
        closeModal={() => setIsOpenConnectWalletModal(false)}
        onLoginSuccess={onClickItem}
      />
    </div>
  );
};

export default MobileMenuDetail;
