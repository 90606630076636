import { combineReducers } from 'redux';
import { pairReducer } from './reducers/pairs';
import { orderBookReducer } from './reducers/order-book';
import { balancesReducer } from './reducers/balances';
import { walletAnalyticReducer } from './reducers/wallet-analytic';
import { accountReducer } from './reducers/account';
import { networkReducer } from './reducers/network';
import { feeReducer } from './reducers/fee';
import { userReducer } from './reducers/user';
import { themeReducer } from './reducers/theme';
import { tradeReducer } from './reducers/trade';

const appReducer = combineReducers({
  networkIsWrong: networkReducer,
  currentAccount: accountReducer,
  pairs: pairReducer,
  orderBook: orderBookReducer,
  balances: balancesReducer,
  walletAnalytic: walletAnalyticReducer,
  fee: feeReducer,
  user: userReducer,
  theme: themeReducer,
  trade: tradeReducer,
});

export const rootReducer = (state: any, action: any) => appReducer(state, action);
