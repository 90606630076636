import Icon from '@ant-design/icons';
import { Dropdown } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DarkModeIcon, LightModeIcon } from 'src/assets/icons';
import {
  AppearanceIconComponent,
  ArrowRightLanguageIconComponent,
  AssetRedemptionIconComponent,
  LanguageIconComponent,
  MyFNFTSIconComponent,
  MyIAOHistoryIconComponent,
  MyNFTSIconComponent,
  ProfileIconVer2Component,
  SettingIconVer2Component,
  SignOutIconComponent,
} from 'src/assets/icons/IconComponent';
import DisconnectWalletModal from 'src/components/06.modals/DisconnectWalletModal';
import { PATHS, PATHS_IAO } from 'src/constants/paths';
import { renderLang } from 'src/helpers';
import { setRoleAffiliate } from 'src/helpers/storage';
import { useAuth } from 'src/hooks/useAuth';
import { THEME_MODE } from 'src/interfaces/theme';
import { clearAllBalances } from 'src/store/actions/balances';
import { setTheme } from 'src/store/actions/theme';
import { setRoleAffiliateIAO, setStoreInfoUserIAO } from 'src/store/actions/user';
import { useConnectWallet } from 'src/web3/hooks';
import MultiLanguageContent, { languages, LANGUAGE_CODE } from './MultiLanguageContent';
import './profile-detail-content.scss';

export interface IWalletDetailContent {
  isOpenDropdownWrapper?: boolean;
  onClick?: () => void;
}

const Content: React.FC<IWalletDetailContent> = ({ isOpenDropdownWrapper, onClick }) => {
  const { t, i18n } = useTranslation();
  const { isAuth } = useAuth();
  const { disconnectWallet } = useConnectWallet();
  const dispatch = useDispatch();
  const history = useHistory();

  const [language, setLanguage] = useState<any>(
    renderLang(i18n.resolvedLanguage) || LANGUAGE_CODE.ENGLISH,
  );

  const userTheme = useSelector((state: any) => state.theme);

  const [darkMode, setDarkMode] = useState<boolean>(userTheme === THEME_MODE.DARK ? true : false);
  const [isOpenDisconnect, setIsOpenDisconnect] = useState<boolean>(false);

  const [isOpenMultiLanguage, setIsOpenMultiLanguage] = useState(false);

  const handleDisconnect = () => {
    disconnectWallet();
    dispatch(clearAllBalances());
    dispatch(setStoreInfoUserIAO(''));
    dispatch(setRoleAffiliateIAO(''));
    setRoleAffiliate('');
    history.push(PATHS.exchange());
  };

  const items = [
    {
      className: 'pb-0',
      icon: MyIAOHistoryIconComponent,
      label: t('layout.header.profileDetail.myIAOHistory'),
      path: PATHS_IAO.myIAOEvent(),
    },
    {
      className: 'pb-0',
      icon: MyFNFTSIconComponent,
      label: t('layout.header.profileDetail.myFNFTs'),
      path: PATHS_IAO.myFNFT(),
    },
    {
      className: 'pb-0',
      icon: MyNFTSIconComponent,
      label: t('layout.header.profileDetail.myNFTs'),
      path: PATHS_IAO.myNFTs(),
    },
    {
      className: '',
      icon: AssetRedemptionIconComponent,
      label: t('layout.header.profileDetail.assetRedemption'),
      path: PATHS_IAO.assetRedemption(),
    },
  ];

  const handleChangeTheme = () => {
    setDarkMode(!darkMode);
  };

  const handleToggleMultiLanguagePopup = () => {
    setIsOpenMultiLanguage(!isOpenMultiLanguage);
  };

  useEffect(() => {
    dispatch(setTheme(darkMode ? THEME_MODE.DARK : THEME_MODE.LIGHT));
  }, [darkMode, dispatch]);

  useEffect(() => {
    if (!isOpenDropdownWrapper) {
      setIsOpenMultiLanguage(false);
    }
  }, [isOpenDropdownWrapper]);

  const renderContentWithAuth = () => {
    return (
      <div className={`profile-detail ${userTheme}`}>
        <div className="profile-detail__item-wrapper">
          <a
            className="profile-detail__item justify-flex-start cursor-pointer"
            href={PATHS_IAO.myProfile()}
          >
            <Icon className="item-icon profile-detail-icon" component={ProfileIconVer2Component} />
            <span>{t('layout.header.profileDetail.myProfile')}</span>
          </a>
        </div>

        <div className="line"></div>

        {items.map((item, index) => {
          return (
            <div key={index} className={`profile-detail__item-wrapper ${item.className}`}>
              <a
                className="profile-detail__item justify-flex-start cursor-pointer"
                href={item.path}
              >
                <Icon className="item-icon profile-detail-icon" component={item.icon} />
                <span>{item.label}</span>
              </a>
            </div>
          );
        })}

        <div className="line"></div>

        {/* <div className="profile-detail__item-wrapper pb-0">
          <Dropdown
            overlay={
              <MultiLanguageContent
                language={language}
                onSelectLanguage={setLanguage}
                onClose={handleToggleMultiLanguagePopup}
              />
            }
            placement="bottomRight"
            // trigger={['click']}
            open={isOpenMultiLanguage}
          >
            <div
              className="profile-detail__item justify-between cursor-pointer"
              onClick={handleToggleMultiLanguagePopup}
            >
              <div>
                <Icon className="item-icon profile-detail-icon" component={LanguageIconComponent} />
                <span>
                  {t('layout.header.profileDetail.language')}: {languages[language]?.label}
                </span>
              </div>
              <Icon
                className="cursor-pointer profile-detail-icon"
                component={ArrowRightLanguageIconComponent}
              />
            </div>
          </Dropdown>
        </div> */}

        {/* <div className="profile-detail__item-wrapper">
          <div className="profile-detail__item justify-between">
            <div>
              <Icon className="item-icon profile-detail-icon" component={AppearanceIconComponent} />
              <span>{t('layout.header.profileDetail.appearance')}</span>
            </div>
            <div className="dark-light-mode">
              <span>
                {darkMode
                  ? t('layout.header.profileDetail.darkMode')
                  : t('layout.header.profileDetail.lightMode')}
              </span>
              <img
                className="cursor-pointer"
                src={darkMode ? DarkModeIcon : LightModeIcon}
                alt="icon"
                onClick={handleChangeTheme}
              />
            </div>
          </div>
        </div>

        <div className="line"></div> */}

        <div className="profile-detail__item-wrapper pb-0">
          <a
            className="profile-detail__item justify-flex-start cursor-pointer"
            href={PATHS_IAO.settings()}
          >
            <Icon className="item-icon profile-detail-icon" component={SettingIconVer2Component} />
            <span>{t('layout.header.profileDetail.settings')}</span>
          </a>
        </div>

        <div className="profile-detail__item-wrapper">
          <div
            className="profile-detail__item justify-flex-start cursor-pointer"
            onClick={() => {
              onClick?.();
              setIsOpenDisconnect(true);
            }}
          >
            <Icon className="item-icon profile-detail-icon" component={SignOutIconComponent} />
            <span>{t('layout.header.profileDetail.disconnect')}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderContentNoAuth = () => {
    return (
      <div className={`profile-detail ${userTheme}`}>
        <div className="profile-detail__item-wrapper pb-0">
          <Dropdown
            overlay={
              <MultiLanguageContent
                language={language}
                onSelectLanguage={setLanguage}
                onClose={handleToggleMultiLanguagePopup}
              />
            }
            placement="bottomRight"
            // trigger={['click']}
            open={isOpenMultiLanguage}
          >
            <div
              className="profile-detail__item justify-between cursor-pointer"
              onClick={handleToggleMultiLanguagePopup}
            >
              <div>
                <Icon className="item-icon profile-detail-icon" component={LanguageIconComponent} />
                <span>
                  {t('layout.header.profileDetail.language')}: {languages[language]?.label}
                </span>
              </div>
              <Icon
                className="cursor-pointer profile-detail-icon"
                component={ArrowRightLanguageIconComponent}
              />
            </div>
          </Dropdown>
        </div>

        <div className="profile-detail__item-wrapper">
          <div className="profile-detail__item justify-between">
            <div>
              <Icon className="item-icon profile-detail-icon" component={AppearanceIconComponent} />
              <span>{t('layout.header.profileDetail.appearance')}</span>
            </div>
            <div className="dark-light-mode">
              <span>
                {darkMode
                  ? t('layout.header.profileDetail.darkMode')
                  : t('layout.header.profileDetail.lightMode')}
              </span>
              <img
                className="cursor-pointer"
                src={darkMode ? DarkModeIcon : LightModeIcon}
                alt="icon"
                onClick={handleChangeTheme}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isAuth ? renderContentWithAuth() : renderContentNoAuth()}
      <DisconnectWalletModal
        isOpen={isOpenDisconnect}
        onClose={() => setIsOpenDisconnect(false)}
        onConfirm={handleDisconnect}
      />
    </>
  );
};

export default Content;
