import { useDispatch, useSelector } from 'react-redux';
import { BigNumber } from '@0x/utils';
import { useEffect } from 'react';
import { setBalanceToken } from 'src/store/actions/balances';
import { useBep20Contract } from 'src/web3/contracts/useBep20Contract';
import { useAuth } from 'src/hooks/useAuth';

export const useGetBalanceBep20 = (addressToken: string, isPreventFirstCall = false) => {
  const { isAuth } = useAuth();

  const account = useSelector((state: any) => state.currentAccount);
  const sc = useBep20Contract(addressToken);

  const getBalance = async () => {
    if (!isAuth || !addressToken || isPreventFirstCall) return;
    const balanceToken: BigNumber = await sc.balanceOf(account);
    const balance = balanceToken.toString();

    dispatch(setBalanceToken(addressToken, balance));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, addressToken, isAuth]);

  return [getBalance];
};
