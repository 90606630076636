import Icon from '@ant-design/icons';
import { Modal, ModalProps } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { CloseModalIconComponent } from 'src/assets/icons/IconComponent';
import { DEFAULT_MODAL_WIDTH } from 'src/constants';
import './styles/index.scss';

export interface IFracModalProps extends ModalProps {
  children?: any;
}

const FracModal: React.FC<IFracModalProps> = (props: IFracModalProps) => {
  const { children, footer, width, wrapClassName } = props;
  const userTheme = useSelector((state: any) => state.theme);

  return (
    <Modal
      closeIcon={
        <Icon className={`close-modal-icon ${userTheme}`} component={CloseModalIconComponent} />
      }
      width={width ? width : DEFAULT_MODAL_WIDTH}
      footer={footer ? footer : null}
      destroyOnClose={true}
      {...props}
      wrapClassName={`${wrapClassName ?? ''} frac-modal-wrapper-${userTheme}`}
    >
      {children}
    </Modal>
  );
};

export default FracModal;
