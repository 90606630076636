import { Dropdown, Menu, Space } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React from 'react';
import { useSelector } from 'react-redux';
import { MoreIcon } from 'src/assets/icons';
import { USER_ROLE } from 'src/constants/formatters';

const MoreMenu: React.FC<{
  roleAffiliate: string;
  listMoreMenu: ItemType[];
}> = ({ roleAffiliate, listMoreMenu }) => {
  const userTheme = useSelector((state: any) => state.theme);

  const listMore = listMoreMenu.filter((item: any) =>
    item?.role?.includes(Number(roleAffiliate) || USER_ROLE.NORMAL),
  );

  return (
    <div className="menu-more">
      <Dropdown
        overlay={<Menu items={listMore} />}
        overlayClassName={`dropdown ${userTheme}`}
        placement="bottom"
      >
        <Space>
          <img src={MoreIcon} alt="" />
        </Space>
      </Dropdown>
    </div>
  );
};

export default MoreMenu;
