import Icon from '@ant-design/icons';
import { Select, SelectProps } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ArrowDownSelectIconComponent,
  SearchPairTableIconComponent,
} from 'src/assets/icons/IconComponent';
import useWindowSize from 'src/hooks/useWindowSize';
import './styles.scss';

export type option = { value: string | number; label: string };

interface ISelect extends SelectProps {
  zindexicon?: number;
}

const { Option } = Select;

const suffixIcon = (zindexicon = -1) => {
  return (
    <Icon
      className="item-icon profile-detail-icon"
      style={{ zIndex: Number(zindexicon) }}
      component={ArrowDownSelectIconComponent}
    />
  );
};

const suffixIconMobile = () => {
  return (
    <Icon className="item-icon profile-detail-icon" component={ArrowDownSelectIconComponent} />
  );
};

const FracSelect: React.FC<ISelect> = (props: ISelect) => {
  const { options = [], className, popupClassName, zindexicon = -1 } = props;

  const { isDesktop } = useWindowSize();

  const userTheme = useSelector((state: any) => state.theme);

  return (
    <Select
      {...props}
      className={`frac-select ${className} frac-select-${userTheme} ${
        props.disabled ? 'disable-frac-select' : ''
      } ${userTheme}`}
      suffixIcon={isDesktop ? suffixIcon(zindexicon) : suffixIconMobile()}
      popupClassName={
        popupClassName
          ? `frac-select-popup-common-${userTheme} ${popupClassName}`
          : `frac-select-popup-common-${userTheme}`
      }
    >
      {options?.map((option: any, index: number) => (
        <Option key={index} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default FracSelect;

interface ISelectCustom extends SelectProps {
  label: string;
}

export const FracSelectWithLabel: React.FC<ISelectCustom> = (props: ISelectCustom) => {
  const { options = [], className, label } = props;

  const userTheme = useSelector((state: any) => state.theme);

  return (
    <div
      className={`select-custom frac-select-with-label-${userTheme} ${
        props.disabled ? 'disable-frac-select' : ''
      } ${userTheme}`}
    >
      <label>{label}: </label>
      <Select
        {...props}
        className={`${className ? className : 'frac-select'}`}
        suffixIcon={suffixIcon()}
      >
        {options?.map((option: any, index: number) => (
          <Option key={index} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export const FracSelectWithSearch: React.FC<ISelect> = (props: ISelect) => {
  const { options = [], className, popupClassName } = props;

  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();

  const userTheme = useSelector((state: any) => state.theme);

  const [isFocus, setIsFocus] = useState<any>(false);

  const ref = useRef<any>(null);

  function checkRegex(evt: any) {
    const value = evt.target.value.trim();
    const regex = new RegExp(/^[a-zA-Z0-9/?]*$/);
    if (!regex.test(evt.key) || value.length > 29) {
      evt.preventDefault();
    }
  }

  const handleFocus = (e: any) => {
    setIsFocus(true);
  };

  const handleBlur = () => {
    setIsFocus(false);
  };

  const handleSelect = () => {
    ref.current?.blur();
    handleBlur();
  };

  const handleKeyDown = (e: any) => {
    checkRegex(e);
  };

  return (
    <div
      className={`${isFocus ? 'custom-select-search' : ''} frac-select-with-search-${userTheme} ${
        props.disabled ? 'disable-frac-select' : ''
      } ${userTheme}`}
    >
      {isFocus && (
        <Icon
          className={`search-icon`}
          component={SearchPairTableIconComponent}
          alt="search-icon"
        />
      )}
      <Select
        {...props}
        value={isFocus ? undefined : props.value}
        notFoundContent={t('dex.exchange.orderHistory.noRecordFound')}
        onFocus={handleFocus}
        onInputKeyDown={handleKeyDown}
        ref={ref}
        onSelect={handleSelect}
        onBlur={handleBlur}
        className={`${className ? className : 'frac-select'} frac-select-with-label-${userTheme}`}
        suffixIcon={isDesktop ? suffixIcon() : suffixIconMobile()}
        popupClassName={popupClassName ? popupClassName : `frac-select-popup-common-${userTheme}`}
      >
        {options?.map((option: any, index: number) => (
          <Option key={index} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

interface ISelectLanguage extends SelectProps {
  zindexicon?: number;
  code: string;
}

export const FracSelectWithLanguage: React.FC<ISelectLanguage> = (props: ISelectLanguage) => {
  const { options = [], className, popupClassName, zindexicon = -1, code } = props;

  const { isDesktop } = useWindowSize();

  const userTheme = useSelector((state: any) => state.theme);

  return (
    <Select
      {...props}
      value={code}
      className={`frac-select ${className} frac-select-${userTheme} ${
        props.disabled ? 'disable-frac-select' : ''
      } ${userTheme}`}
      suffixIcon={isDesktop ? suffixIcon(zindexicon) : suffixIconMobile()}
      popupClassName={
        popupClassName
          ? `frac-select-popup-common-${userTheme} ${popupClassName}`
          : `frac-select-popup-common-${userTheme}`
      }
    >
      {options?.map((option: any, index: number) => (
        <Option key={index} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};
