import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MetamaskIcon, WalletConnectIcon } from 'src/assets/icons';
import { WALLET_IS_ADMIN_ERROR_CODE, WALLET_IS_DEACTIVATED_ERROR_CODE } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import { useAuth } from 'src/hooks/useAuth';
import useWindowSize from 'src/hooks/useWindowSize';
import { WEB3_ERROR } from 'src/types';
import { ConnectorKey } from 'src/web3/connectors';
import { CONNECTOR_KEY } from 'src/web3/constants/storages';
import { useActiveWeb3React, useConnectWallet } from 'src/web3/hooks';
import FracModal, { IFracModalProps } from '.';
import { fracToast2 } from '../07.toast';
import './styles/connect-wallet-modal.scss';

interface IConnectWalletModalProps extends IFracModalProps {
  closeModal?: () => void;
  onLoginSuccess?: () => void;
  setIsOpenDeactivatedModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
interface IConnectBoxProps {
  text: string;
  icon: string;
  isActive: boolean;
  onClick: () => void;
}

const ConnectBox: React.FC<IConnectBoxProps> = (props: IConnectBoxProps) => {
  const { icon, isActive, onClick, text } = props;

  return (
    <div className={`box-connect ${isActive ? 'active-connect' : ''}`} onClick={onClick}>
      <div className="group-content">
        <img src={icon} alt="icon" />
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

const ConnectWalletModal: React.FC<IConnectWalletModalProps> = (
  props: IConnectWalletModalProps,
) => {
  const { t } = useTranslation();
  const { signIn, isAuth, signInWalletConnect } = useAuth();
  const { addSwitchOrAddNetwork } = useConnectWallet();
  const connectorName = localStorage.getItem(CONNECTOR_KEY);
  const [isMetamask, setIsMetamask] = useState<boolean>(true);
  const [userLock, setUserLock] = useState<boolean>(false);
  const { deactivate } = useActiveWeb3React();
  const userTheme = useSelector((state: any) => state.theme);
  const { isMobile } = useWindowSize();
  const { open, closeModal, onLoginSuccess, setIsOpenDeactivatedModal } = props;

  // states

  const getAddress = async () => {
    try {
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const signer = web3Provider.getSigner();
      await signer.getAddress();
    } catch (error) {
      console.log('USER LOCK');
      setUserLock(true);
    }
  };

  const handleCloseModal = () => {
    if (closeModal) {
      setIsMetamask(true);
      closeModal();
    }
  };

  const isMetaMaskInstalled = () => {
    return Boolean((window as any).ethereum && (window as any).ethereum.isMetaMask);
  };

  const handleClick = async (isMetamask = false) => {
    try {
      if (isMobile && !isMetaMaskInstalled()) {
        const metamaskAppDeepLink = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;
        window.location.assign(metamaskAppDeepLink);
      }

      if (isMetamask) {
        await signIn(ConnectorKey.injected);
        setUserLock(false);
        onLoginSuccess && onLoginSuccess();
      } else {
        // await connectWallet(ConnectorKey.walletConnect);
        await signInWalletConnect(ConnectorKey.walletConnect);
        setUserLock(false);
      }
      handleCloseModal();
    } catch (error: any) {
      handleError(error, isMetamask ? ConnectorKey.injected : ConnectorKey.walletConnect);
    }
  };

  const handleUserReject = (error: WEB3_ERROR) => {
    if (error.description?.name === 'UserRejectedRequestError') {
      // user reject connect account
      fracToast2.error(t(MESSAGES.MC13));
    }
    if (error.description?.code === 'ACTION_REJECTED') {
      // user reject sign message
      fracToast2.error(t(MESSAGES.MC13));
      deactivate();
    }
  };

  const handleAddOrSwitchNetwork = async (connectorKey: ConnectorKey) => {
    const isRequested = await addSwitchOrAddNetwork(connectorKey);
    if (!isRequested) {
      fracToast2.error(t(MESSAGES.MC14), t(MESSAGES.MC15));
    } else {
      if (connectorKey === ConnectorKey.injected) {
        await signIn(ConnectorKey.injected);
      } else {
        await signInWalletConnect(ConnectorKey.walletConnect);
      }
      handleCloseModal();
    }
  };

  const handleError = (error: WEB3_ERROR, connectorKey: ConnectorKey) => {
    const codeErr = error?.description?.response?.data?.code;

    if (codeErr === WALLET_IS_ADMIN_ERROR_CODE) {
      fracToast2.error(t('dex.message.MC51'));
      return;
    }

    if (codeErr === WALLET_IS_DEACTIVATED_ERROR_CODE) {
      setIsOpenDeactivatedModal?.(true);
      closeModal?.();
      return;
    }

    switch (error.type) {
      case 'user_reject':
        handleUserReject(error);
        break;
      case 'un_support_chain':
        handleAddOrSwitchNetwork(connectorKey);
        break;
      case 'no_eth_provider':
        setIsMetamask(false);
        break;
      default:
        fracToast2.error(t(MESSAGES.MC3));
    }
  };

  const handleCancel = () => {
    setIsMetamask(true);
    handleCloseModal();
  };

  useEffect(() => {
    getAddress();
  }, []);

  return (
    <FracModal
      open={open}
      onCancel={handleCancel}
      width={620}
      title={t('layout.header.menu.connectWalletModalTitle')}
      wrapClassName={`connect-wallet-modal-${userTheme}`}
    >
      {(!isMetaMaskInstalled() || !isMetamask || userLock) && (
        <div className="error-message-metamask">{t(MESSAGES.MC12)}</div>
      )}
      <div className="type-connect-group">
        <ConnectBox
          icon={MetamaskIcon}
          onClick={() => {
            if (isMetamask) handleClick(true);
          }}
          text="Metamask"
          isActive={isAuth && connectorName === ConnectorKey.injected}
        />
        <ConnectBox
          icon={WalletConnectIcon}
          onClick={() => {
            if (closeModal) closeModal();
            if (isMetamask) handleClick();
          }}
          text="WalletConnect"
          isActive={isAuth && connectorName === ConnectorKey.walletConnect}
        />
      </div>
      <div className="footer-connect-modal">
        <div onClick={() => window.open('https://metamask.io/about/')}>
          {t('layout.header.menu.connectWalletModalFooter')}
        </div>
      </div>
    </FracModal>
  );
};

export default ConnectWalletModal;
