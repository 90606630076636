import axios from 'axios';
import { get } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { USER_ROLE } from 'src/constants/formatters';
import { PATHS, PATHS_IAO } from 'src/constants/paths';
import { IAOEndPoint } from 'src/hooks/useLogin';
import MenuItem from './MenuItem';
import MoreMenu from './MoreMenu';
import './styles.scss';

export interface IMenuItem {
  label: string;
  path?: string;
  subMenus?: {
    label: string;
    path: string;
  }[];
  role?: USER_ROLE[];
}

const SubMenu: React.FC = () => {
  const { t } = useTranslation();
  const urlBecomeAffiliate = useRef<string>('');
  const fetchUrl = async () => {
    try {
      await axios.get(`${IAOEndPoint}/affiliate/setting`).then((res: any) => {
        urlBecomeAffiliate.current = res.data.data.registrationUrl;
      });
    } catch (error) {
      console.log('err', error);
    }
  };

  useEffect(() => {
    fetchUrl();
  });

  const { roleAffiliate } = useSelector((state: any) => ({
    roleAffiliate: get(state, 'user.roleAffiliate', ''),
  }));

  const renderListMenu = () => {
    const listMenu: IMenuItem[] = [
      {
        label: t('layout.header.menu.exchange'),
        path: PATHS.exchange(),
        subMenus: [
          {
            label: t('layout.header.subMenu.marketOverview'),
            path: PATHS.marketOverview(),
          },
          {
            label: t('layout.header.subMenu.trading'),
            path: PATHS.exchange(),
          },
        ],
        role: [
          USER_ROLE.NORMAL,
          USER_ROLE.AFFILIATE_SUB_1,
          USER_ROLE.AFFILIATE_SUB_2,
          USER_ROLE.MASTER_AFFILIATE,
        ],
      },
      {
        label: t('layout.header.menu.IAO'),
        path: PATHS_IAO.iao(),
        role: [
          USER_ROLE.NORMAL,
          USER_ROLE.AFFILIATE_SUB_1,
          USER_ROLE.AFFILIATE_SUB_2,
          USER_ROLE.MASTER_AFFILIATE,
        ],
      },
      {
        label: t('layout.header.menu.affiliates'),
        path: PATHS_IAO.affiliates(),
        role: [
          // USER_ROLE.NORMAL,
          USER_ROLE.AFFILIATE_SUB_1,
          USER_ROLE.AFFILIATE_SUB_2,
          USER_ROLE.MASTER_AFFILIATE,
        ],
      },
      // {
      //   label: t('layout.header.menu.stakeDAD'),
      //   path: PATHS_IAO.stake(),
      //   subMenus: [
      //     { label: t('layout.header.menu.stake'), path: PATHS_IAO.stake() },
      //     { label: t('layout.header.menu.unStake'), path: PATHS_IAO.unStake() },
      //     { label: t('layout.header.menu.history'), path: PATHS_IAO.historyStake() },
      //   ],
      //   role: [
      //     USER_ROLE.NORMAL,
      //     USER_ROLE.AFFILIATE_SUB_1,
      //     USER_ROLE.AFFILIATE_SUB_2,
      //     USER_ROLE.MASTER_AFFILIATE,
      //   ],
      // },
    ];

    return listMenu
      .filter((itemFilter: IMenuItem) =>
        itemFilter?.role?.includes(Number(roleAffiliate) || USER_ROLE.NORMAL),
      )
      .map((itemMap: IMenuItem, i: number) => {
        return (
          <MenuItem key={i} label={itemMap.label} path={itemMap.path} subMenus={itemMap.subMenus} />
        );
      });
  };

  const renderListMoreMenu = () => {
    const listMoreMenu = [
      // {
      //   key: '1',
      //   label: (
      //     <div
      //       onClick={() => {
      //         window.open(urlBecomeAffiliate.current ?? '');
      //       }}
      //     >
      //       {t('layout.header.menu.becomeAffiliates')}
      //     </div>
      //   ),
      //   role: [
      //     USER_ROLE.NORMAL,
      //     // USER_ROLE.AFFILIATE_SUB_1,
      //     // USER_ROLE.AFFILIATE_SUB_2,
      //     // USER_ROLE.MASTER_AFFILIATE,
      //   ],
      // },
      // {
      //   key: '2',
      //   label: (
      //     <div
      //       onClick={() => {
      //         window.location.replace(PATHS_IAO.userGuides() ?? '');
      //       }}
      //     >
      //       {t('layout.header.menu.userGuides')}
      //     </div>
      //   ),
      //   role: [
      //     USER_ROLE.NORMAL,
      //     USER_ROLE.AFFILIATE_SUB_1,
      //     USER_ROLE.AFFILIATE_SUB_2,
      //     USER_ROLE.MASTER_AFFILIATE,
      //   ],
      // },
      {
        key: '3',
        label: (
          <div
            onClick={() => {
              window.location.replace(PATHS_IAO.aboutUs() ?? '');
            }}
          >
            {t('layout.header.menu.contactUs')}
          </div>
        ),
        role: [
          USER_ROLE.NORMAL,
          USER_ROLE.AFFILIATE_SUB_1,
          USER_ROLE.AFFILIATE_SUB_2,
          USER_ROLE.MASTER_AFFILIATE,
        ],
      },
    ];

    return <MoreMenu listMoreMenu={listMoreMenu} roleAffiliate={roleAffiliate} />;
  };

  return (
    <>
      {renderListMenu()}
      {renderListMoreMenu()}
    </>
  );
};

export default React.memo(SubMenu);
