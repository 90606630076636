import { Dropdown } from 'antd';
import React from 'react';
import Content from './Content';
import { IWalletDetailContent } from './Content';

interface IWalletDetail extends IWalletDetailContent {
  children: React.ReactNode;
  isDisable?: boolean;
}

const WalletDetail: React.FC<IWalletDetail> = ({ children, isDisable = false, ...props }) => {
  return (
    <Dropdown
      trigger={['hover']}
      overlay={!isDisable ? <Content {...props} /> : <></>}
      placement="bottomRight"
      overlayClassName="dropdown-wrapper"
    >
      {children}
    </Dropdown>
  );
};

export default WalletDetail;
