import { Layout } from 'antd';
import BigNumber from 'bignumber.js';
import _, { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppHeader from 'src/components/01.layout/header/AppHeader';
import { OrderType } from 'src/constants/exchange';
import { MESSAGES } from 'src/constants/messages';
import {
  getStorageUserLanguage,
  getStorageUserTheme,
  hasLoggedInIaoDex,
  setRoleAffiliate,
} from 'src/helpers/storage';
import { useAuth } from 'src/hooks/useAuth';
import useWindowSize from 'src/hooks/useWindowSize';
import i18n from 'src/i18n/i18n';
import { THEME_MODE } from 'src/interfaces/theme';
import { NotificationService } from 'src/services/notification-service';
import eventBus from 'src/socket/event-bus';
import { SocketEvent } from 'src/socket/SocketEvent';
import { setTheme } from 'src/store/actions/theme';
import { setRoleAffiliateIAO } from 'src/store/actions/user';
import { useWalletListener } from 'src/web3/hooks';
import { fracToast2 } from '../07.toast';
import MobileMenuDetail from './header/MobileMenuDetail';
import NotificationContent from './header/MobileMenuDetail/NotificationContent';
import ModalNotificationDetail from './header/ModalNotificationDetail';
import { LANGUAGE_CODE } from './header/ProfileDetail/MultiLanguageContent';
import './styles.scss';

const { Content } = Layout;

export const formatDate = 'DD-MM-YYYY HH:mm:ss';

interface ILayoutProps {
  children?: React.ReactNode;
}

export enum ThemesMode {
  dark = 'dark',
  light = 'light',
}

function generateLanguage(language: string) {
  switch (language) {
    case 'en':
      return LANGUAGE_CODE.ENGLISH;
    case 'cn':
      return LANGUAGE_CODE.CHINESE;
    case 'zh':
      return LANGUAGE_CODE.CHINESE;
    case 'ja':
      return LANGUAGE_CODE.JAPANESE;
    case 'jp':
      return LANGUAGE_CODE.JAPANESE;
    case 'vi':
      return LANGUAGE_CODE.VIETNAMESE;
    default:
      break;
  }
}

const LayoutDefault: React.FC<ILayoutProps> = ({ children }) => {
  const account = useSelector((state: any) => state.currentAccount);
  const userTheme = useSelector((state: any) => state.theme);
  const { isAuth } = useAuth();
  const { isDesktop } = useWindowSize();

  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);
  const [isOpenNotificationMobile, setIsOpenNotificationMobile] = useState(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [notifyDetail, setNotifyDetail] = useState<any>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userLoginSuccess = useMemo(() => {
    return isAuth && !!account;
  }, [isAuth, account]);

  useWalletListener();

  const handleOpenMenuMobile = (bool: boolean) => {
    setIsOpenMenuMobile(bool);
    setIsOpenNotificationMobile(false);
  };

  const handleOpenNotificationMobile = (bool: boolean) => {
    setIsOpenMenuMobile(false);
    setIsOpenNotificationMobile(bool);
  };

  useEffect(() => {
    isDesktop && setIsOpenMenuMobile(false);
  }, [isDesktop]);

  const getCountUnRead = async () => {
    const notifySv = new NotificationService();
    const data = await notifySv.getCountNotRead();
    setNotificationCount(get(data, 'data', 0));
  };

  useEffect(() => {
    if (userLoginSuccess && account) {
      getCountUnRead();
    }
    // eventBus.on(SocketEvent.delete_pair, () => {
    //   getCountUnRead();
    // });

    // eslint-disable-next-line
  }, [userLoginSuccess, account]);

  // useEffect(() => {
  //   if (hasLoggedInIaoDex() && !account) {
  //     console.log('login iao & dex');
  //     dispatch(setAccessTokenRedux(getStorageJwtToken() as string));
  //     dispatch(setCurrentAccount(getStorageUserAddress() as string));
  //     hasSigned();
  //     const connectorKey = getStorageWallet();
  //     if (connectorKey === ConnectorKey.walletConnect) {
  //       connectWallet(ConnectorKey.walletConnect);
  //     } else {
  //       connectWallet(ConnectorKey.injected);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    window.addEventListener('storage', (e) => {
      if (
        getStorageUserTheme() &&
        getStorageUserTheme()?.toLocaleLowerCase() !== userTheme.toLocaleLowerCase()
      ) {
        if (getStorageUserTheme() === THEME_MODE.DARK?.toLocaleLowerCase()) {
          dispatch(setTheme(THEME_MODE.DARK));
          document.documentElement.setAttribute('data-theme', THEME_MODE.DARK.toLocaleLowerCase());
        } else {
          dispatch(setTheme(THEME_MODE.LIGHT));
          document.documentElement.setAttribute('data-theme', THEME_MODE.LIGHT.toLocaleLowerCase());
        }
      }

      if (getStorageUserLanguage() && getStorageUserLanguage() !== 'en') {
        i18n.changeLanguage(generateLanguage(getStorageUserLanguage() as string));
      } else {
        i18n.changeLanguage(LANGUAGE_CODE.ENGLISH);
      }

      if (!hasLoggedInIaoDex()) {
        dispatch(setRoleAffiliateIAO(''));
        setRoleAffiliate('');
      }
    });
    return () => {
      window.removeEventListener('storage', (e) => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    eventBus.on(SocketEvent.CancelOrder, (data: any) => {
      const isLimitCancelOrder = new BigNumber(_.get(data, 'type', 0)).eq(OrderType.Limit);
      isLimitCancelOrder &&
        fracToast2.error(
          t(MESSAGES.MC52),
          <div>
            <div>{t(MESSAGES.MC55)}</div>
            <div>{t(MESSAGES.MC53)}</div>
          </div>,
        );
    });

    return () => {
      eventBus.remove(SocketEvent.CancelOrder, (data: any) => {});
    };
  }, [t]);

  return (
    <div id="layout">
      <Layout className="container">
        <AppHeader
          isOpenMenuMobile={isOpenMenuMobile}
          setIsOpenMenuMobile={handleOpenMenuMobile}
          isOpenNotificationMobile={isOpenNotificationMobile}
          setIsOpenNotificationMobile={handleOpenNotificationMobile}
          getCountUnRead={getCountUnRead}
          notificationCount={notificationCount}
        />
        <Layout className={`site-layout ${userTheme}`}>
          <Content className="site-layout-background">
            <div className={!isDesktop && isOpenMenuMobile ? '' : 'display-none'}>
              <MobileMenuDetail
                address={userLoginSuccess ? (account as string) : ''}
                onClickItem={() => setIsOpenMenuMobile(false)}
                isOpen={!isDesktop && isOpenMenuMobile}
              />
            </div>
            <div className={!isDesktop && isOpenNotificationMobile ? '' : 'display-none'}>
              <NotificationContent
                count={notificationCount}
                setNotifyDetail={(data: any) => {
                  setNotifyDetail(data);
                }}
                getCountUnRead={getCountUnRead}
              />
            </div>
            <div
              className={
                isDesktop || (!isOpenMenuMobile && !isOpenNotificationMobile) ? '' : 'display-none'
              }
            >
              {children}
            </div>
            {notifyDetail && (
              <ModalNotificationDetail
                isOpen={!!notifyDetail}
                onClose={() => setNotifyDetail(null)}
                notifyDetail={notifyDetail}
              />
            )}
          </Content>
        </Layout>
        {/* <Footer /> */}
      </Layout>
    </div>
  );
};

export default LayoutDefault;
