import { UnsupportedChainIdError } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fracToast2 } from 'src/components/07.toast';
import { MESSAGES } from 'src/constants/messages';
import { removeStorageJwtToken } from 'src/helpers/storage';
import { clearCurrentAccount } from 'src/store/actions/account';
import { setIsWrongNetWork } from 'src/store/actions/network';
import { WEB3_ERROR } from 'src/types';
import { ConnectorKey, connectors } from 'src/web3/connectors';
import { CONNECTOR_KEY } from 'src/web3/constants/storages';
import { setupNetwork } from 'src/web3/helpers/setupNetwork';
import { useActiveWeb3React } from 'src/web3/hooks/useActiveWeb3React';
import { activateInjectedProvider } from '../helpers/activateInjectedProvider';

/**
 * Hook for connect/disconnect to a wallet
 * @returns `connectWallet` and `disconnectWallet` functions .
 */
export const useConnectWallet = () => {
  const { activate, deactivate, library } = useActiveWeb3React();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  async function connectWallet(connectorKey: ConnectorKey) {
    const connector = connectors[connectorKey];
    try {
      activateInjectedProvider(connectorKey);

      await activate(connector, undefined, true);
      setStorageWallet(connectorKey);
    } catch (error: any) {
      let baseError = {
        type: 'user_reject',
        message: error?.message,
        description: error,
      } as WEB3_ERROR;

      if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect ||
        (error instanceof Error && error.message === 'User denied account authorization') // Coinbase wallet
      ) {
        if (connector instanceof WalletConnectConnector) {
          const walletConnector = connector;
          walletConnector.walletConnectProvider = undefined;
        }
      }

      if (error instanceof UnsupportedChainIdError) {
        baseError = {
          ...baseError,
          type: 'un_support_chain',
        } as WEB3_ERROR;
        dispatch(setIsWrongNetWork(true));
        fracToast2.error(t(MESSAGES.MC14), t(MESSAGES.MC49));
        const result = await setupNetwork();
        if (result) {
          dispatch(setIsWrongNetWork(false));
        }
      }

      if (error instanceof NoEthereumProviderError) {
        baseError = {
          ...baseError,
          type: 'no_eth_provider',
        } as WEB3_ERROR;
      }
      throw baseError;
    }
  }

  async function disconnectWallet() {
    removeWalletConnectData();
    removeStorageWallet();
    await deactivate();
    dispatch(clearCurrentAccount());
    dispatch(setIsWrongNetWork(false));
    removeStorageJwtToken();
  }

  async function addSwitchOrAddNetwork(connectorKey: ConnectorKey) {
    const connector = connectors[connectorKey];
    const provider = await connector.getProvider();
    let result: boolean = false;
    if (connectorKey === ConnectorKey.walletConnect) {
      result = await setupNetwork(library?.provider);
      if (result) {
        dispatch(setIsWrongNetWork(false));
      }
    } else {
      result = await setupNetwork(provider);
      if (result) {
        dispatch(setIsWrongNetWork(false));
      }
    }
    return result;
  }
  return { connectWallet, disconnectWallet, addSwitchOrAddNetwork };
};

function setStorageWallet(connector: ConnectorKey) {
  localStorage.setItem(CONNECTOR_KEY, connector);
}

function removeStorageWallet() {
  window.localStorage.removeItem(CONNECTOR_KEY);
}

function removeWalletConnectData() {
  if (window.localStorage.getItem('walletconnect')) {
    connectors.walletConnect.close();
    connectors.walletConnect.walletConnectProvider = undefined;

    window.localStorage.removeItem('walletconnect');
  }
}
