import Icon from '@ant-design/icons';
import BigNumber from 'bignumber.js';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { AlertIconComponent, MailIconComponent } from 'src/assets/icons/IconComponent';
import { BooleanBE, FORMAT_DATE_NOTIFY, NOTIFICATION_TYPE } from 'src/constants';
import { PATHS_IAO } from 'src/constants/paths';
import { ellipseAddress, sliceString } from 'src/helpers';
import { getStorageUserLanguage } from 'src/helpers/storage';
import { useAuth } from 'src/hooks/useAuth';
import { NotificationService } from 'src/services/notification-service';
import eventBus from 'src/socket/event-bus';
import { SocketEvent } from 'src/socket/SocketEvent';
import { formatDate } from '../..';
import { detectLanguage } from '../NotificationContent';
import './mobile-notification-content.scss';
import { REACT_APP_CHAIN_ID, REACT_APP_NETWORK_NAME } from 'src/web3/constants/envs';

// const formatCount = (count: number): number | string => {
//   if (count < 1) return '0';
//   if (count < 100) return count;
//   return '99+';
// };

export enum NotificationTypeBE {
  OrderBookTradingFee = 'OrderBookTradingFee',
  PoolSwapFee = 'PoolSwapFee',
  PoolRequest = 'PoolRequest',
  Wallet = 'Wallet',
  Confidence = 'Confidence',
  Pair = 'Pair',
}
interface NotificationRecord {
  id: number;
  user_id: number;
  is_read: BooleanBE;
  is_show: BooleanBE;
  is_trash: BooleanBE;
  title: string;
  message: string;
  n_status_id: string;
  created_at: string;
  updated_at: string;
  type: NotificationTypeBE;
}

// const NOTIFICATION_EMAIL_TYPE = [NotificationTypeBE.Pair];

const SIZE = 5;

const NotificationContent: React.FC<any> = ({ count, setNotifyDetail, getCountUnRead }: any) => {
  const { t } = useTranslation();
  const { isAuth } = useAuth();

  const userTheme = useSelector((state: any) => state.theme);
  // const language = i18n.resolvedLanguage;
  const account = useSelector((state: any) => state.currentAccount);

  const [notificationData, setNotificationData] = useState<NotificationRecord[]>([]);

  const userLoginSuccess = useMemo(() => {
    return isAuth && !!account;
  }, [isAuth, account]);

  // NotificationType
  const getListNotification = async () => {
    const notifySv = new NotificationService();
    const data = await notifySv.getListNotifications({
      page: 1,
      size: SIZE,
    });
    const dataConvert = data?.data.map((item: any) => ({ ...item, data: JSON.parse(item.data) }));
    setNotificationData(dataConvert || []);
  };

  const putMarkRead = async (id?: number) => {
    const notifySv = new NotificationService();
    if (id) {
      await notifySv.asRead({ ids: [id] });
    } else {
      await notifySv.asRead();
    }
    getListNotification();
    getCountUnRead?.();
  };

  const markReadAll = async () => {
    putMarkRead();
  };

  const markReadOne = async (id: number) => {
    putMarkRead(id);
  };

  const handleClickItem = async (item: any) => {
    markReadOne(item?.id);
    setNotifyDetail(item);
  };

  useEffect(() => {
    if (userLoginSuccess && account) getListNotification();
  }, [account, count, userLoginSuccess]);

  useEffect(() => {
    if (account) {
      eventBus.on(SocketEvent.Notification, (notify: any) => {
        getListNotification();
        getCountUnRead();
      });
    }
    return () => {
      eventBus.remove(SocketEvent.Notification, (notify: any) => {
        getListNotification();
        getCountUnRead();
      });
    };
  }, [account, getCountUnRead]);

  const renderTitleNoti = (noti: any) => {
    switch (noti.type) {
      case NOTIFICATION_TYPE.UpdateTradingLevel:
        return t('notification.type.updateTradingFee');

      case NOTIFICATION_TYPE.PairCreated:
        return t('notification.type.created');

      case NOTIFICATION_TYPE.PairDeleted:
        return t('notification.type.deleted', {
          pairName: `${noti.data.baseSymbol}/${noti.data.quoteSymbol}`,
        });

      case NOTIFICATION_TYPE.UserTierChanged:
        return t('notification.type.userTierChanged');

      case NOTIFICATION_TYPE.WHITELISTS:
        return t('notification.type.whiteLits');

      case NOTIFICATION_TYPE.IAO_PARTICIPATION_START:
        return t('notification.type.iaoParticipationStart');

      case NOTIFICATION_TYPE.IAO_VAULT_FAILED:
        return t('notification.type.iaoVaultFailed');

      case NOTIFICATION_TYPE.IAO_VAULT_SUCCEEDED:
        return t('notification.type.iaoVaultSuccess');

      case NOTIFICATION_TYPE.IAO_NON_VAULT_SUCCEEDED:
        return t('notification.type.iaoNonVaultSuccess');

      case NOTIFICATION_TYPE.REJECT_IAO_REVENUE:
        return t('notification.iaoResultRejectedTitle');

      case NOTIFICATION_TYPE.REDEMPTION_REQUEST_APPROVAL:
        return t('notification.redemptionRequestApprovedTitle');

      case NOTIFICATION_TYPE.REDEMPTION_REQUEST_REJECT:
        return t('notification.redemptionRequestRejectedTitle');

      case NOTIFICATION_TYPE.NEW_AFFILIATE_OFFER:
        return t('notification.newAffiliateOfferTitle');

      case NOTIFICATION_TYPE.ACCEPT_AFFILIATE_OFFER:
        return t('notification.afffiliateOfferAcceptedTitle');

      case NOTIFICATION_TYPE.ANNOUNCEMENT:
        return _.get(noti, `data.title[${detectLanguage(getStorageUserLanguage() ?? '')}]`, '');

      case NOTIFICATION_TYPE.CANCEL_WITHDRAWAL:
        return t('notification.withdrawRequestCanceledTitle');

      case NOTIFICATION_TYPE.WITHDRAW_REQUEST_SUCCEEDED:
        return t('notification.withdrawRequestSuccessTitle');

      case NOTIFICATION_TYPE.WITHDRAW_REQUEST_UNSUCCEEDED:
        return t('notification.withdrawRequestUnsuccessTitle');

      default:
        return '';
    }
  };

  const renderMessageNoti = (noti: any) => {
    const gotoIaoEventDetail = (e: React.MouseEvent) => {
      e.stopPropagation();

      return window.open(
        `${PATHS_IAO.viewAll()}/${_.get(noti, 'data.iaoEventId', '')}`,
        '_blank',
        'noopener,noreferrer',
      );
    };

    const gotoRedempDetail = (e: React.MouseEvent) => {
      e.stopPropagation();

      return window.open(
        `${PATHS_IAO.assetRedemption()}?requestId=${_.get(noti, 'data.redempId', '')}`,
        '_blank',
        'noopener,noreferrer',
      );
    };

    const gotoContactUs = (e: React.MouseEvent) => {
      e.stopPropagation();

      return window.open(`${PATHS_IAO.aboutUs()}`, '_blank', 'noopener,noreferrer');
    };

    const gotoMyProfile = (e: React.MouseEvent) => {
      e.stopPropagation();

      return window.open(`${PATHS_IAO.myProfile()}`, '_blank', 'noopener,noreferrer');
    };

    const gotoWithdrawRequest = (e: React.MouseEvent, withdrawalId: number) => {
      e.stopPropagation();

      return window.open(
        `${PATHS_IAO.withdrawRequest()}/${withdrawalId}`,
        '_blank',
        'noopener,noreferrer',
      );
    };

    const gotoBscScanDetailAddress = (e: React.MouseEvent, address: string) => {
      e.stopPropagation();

      return window.open(
        `${process.env.REACT_APP_BLOCK_EXPLORER_URL}/address/${address}`,
        '_blank',
        'noopener,noreferrer',
      );
    };

    switch (noti.type) {
      case NOTIFICATION_TYPE.UpdateTradingLevel:
        return t('notification.message.updateTradingFee', {
          feeType: noti.data.feeType === 1 ? 'Taker fee' : 'Maker fee',
          network: noti.data.network === REACT_APP_CHAIN_ID ? REACT_APP_NETWORK_NAME : '',
          tierName: noti.data.tierName,
          oldValue: new BigNumber(noti.data.oldValue).multipliedBy(100).toNumber(),
          newValue: new BigNumber(noti.data.newValue).multipliedBy(100).toNumber(),
          date: moment(noti.data.createdAt * 1000).format(FORMAT_DATE_NOTIFY),
        });

      case NOTIFICATION_TYPE.PairCreated:
        return t('notification.message.created', {
          pairName: `${noti.data.baseSymbol}/${noti.data.quoteSymbol}`,
          date: moment(noti.data.createdAt * 1000).format(FORMAT_DATE_NOTIFY),
        });

      case NOTIFICATION_TYPE.PairDeleted:
        return t('notification.message.deleted', {
          pairName: `${noti.data.baseSymbol}/${noti.data.quoteSymbol}`,
          date: moment(noti.data.deletedAt * 1000).format(FORMAT_DATE_NOTIFY),
        });

      case NOTIFICATION_TYPE.UserTierChanged:
        return t('notification.message.userTierChanged', {
          oldTier: noti.data.oldTier,
          newTier: noti.data.newTier,
          date: moment(noti.data.createdAt * 1000).format(FORMAT_DATE_NOTIFY),
        });

      case NOTIFICATION_TYPE.WHITELISTS:
        // return t('notification.message.whiteLits', {
        //   eventName: _.get(noti, 'data.iaoEventName.en', ''),
        // });

        return (
          <Trans
            i18nKey="notification.message.whiteLits"
            values={{
              eventName: sliceString(_.get(noti, 'data.iaoEventName.en', '')),
            }}
            components={[
              <span key="0" onClick={gotoIaoEventDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.IAO_PARTICIPATION_START:
        // return t('notification.message.iaoParticipationStart', {
        //   eventName: _.get(noti, 'data.iaoEventName.en', ''),
        // });

        return (
          <Trans
            i18nKey="notification.message.iaoParticipationStart"
            values={{
              eventName: sliceString(_.get(noti, 'data.iaoEventName.en', '')),
            }}
            components={[
              <span key="0" onClick={gotoIaoEventDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.IAO_VAULT_FAILED:
        // return t('notification.message.iaoVaultFailed', {
        //   eventName: _.get(noti, 'data.iaoEventName.en', ''),
        // });

        return (
          <Trans
            i18nKey="notification.message.iaoVaultFailed"
            values={{
              eventName: sliceString(_.get(noti, 'data.iaoEventName.en', '')),
            }}
            components={[
              <span key="0" onClick={gotoIaoEventDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.IAO_VAULT_SUCCEEDED:
        // return t('notification.message.iaoVaultSuccess', {
        //   eventName: _.get(noti, 'data.iaoEventName.en', ''),
        // });

        return (
          <Trans
            i18nKey="notification.message.iaoVaultSuccess"
            values={{
              eventName: sliceString(_.get(noti, 'data.iaoEventName.en', '')),
            }}
            components={[
              <span key="0" onClick={gotoIaoEventDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.IAO_NON_VAULT_SUCCEEDED:
        // return t('notification.message.iaoNonVaultSuccess', {
        //   eventName: _.get(noti, 'data.iaoEventName.en', ''),
        // });

        return (
          <Trans
            i18nKey="notification.message.iaoNonVaultSuccess"
            values={{
              eventName: sliceString(_.get(noti, 'data.iaoEventName.en', '')),
            }}
            components={[
              <span key="0" onClick={gotoIaoEventDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.REJECT_IAO_REVENUE:
        // return t('notification.iaoResultRejectedDes', {
        //   eventName: _.get(noti, 'data.iaoEventName.en', ''),
        // });

        return (
          <Trans
            i18nKey="notification.iaoResultRejectedDes"
            values={{
              eventName: sliceString(_.get(noti, 'data.iaoEventName.en', '')),
            }}
            components={[
              <span key="0" onClick={gotoIaoEventDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.REDEMPTION_REQUEST_APPROVAL:
        // return t('notification.redemptionRequestApprovedDesc', {
        //   redempId: _.get(noti, 'data.redempId', ''),
        // });

        return (
          <Trans
            i18nKey="notification.redemptionRequestApprovedDesc"
            values={{
              redempId: _.get(noti, 'data.redempId', ''),
            }}
            components={[
              <span key="0" onClick={gotoRedempDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.REDEMPTION_REQUEST_REJECT:
        // return t('notification.redemptionRequestRejectedDesc', {
        //   redempId: _.get(noti, 'data.redempId', ''),
        // });

        return (
          <Trans
            i18nKey="notification.redemptionRequestRejectedDesc"
            values={{
              redempId: _.get(noti, 'data.redempId', ''),
            }}
            components={[
              <span key="0" onClick={gotoRedempDetail} className="event_name_link"></span>,
              <span key="1" onClick={gotoContactUs} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.NEW_AFFILIATE_OFFER:
        // return t('notification.newAffiliateOfferDesc');

        return (
          <Trans
            i18nKey="notification.newAffiliateOfferDesc"
            components={[<span key="0" onClick={gotoMyProfile} className="event_name_link"></span>]}
          />
        );

      case NOTIFICATION_TYPE.ACCEPT_AFFILIATE_OFFER:
        // return t('notification.afffiliateOfferAcceptedDesc', {
        //   walletAddress: _.get(noti, 'data.userWalletAddress', ''),
        //   commissionRate: _.get(noti, 'data.commissionRate', ''),
        // });

        return (
          <Trans
            i18nKey="notification.afffiliateOfferAcceptedDesc"
            values={{
              walletAddress: ellipseAddress(_.get(noti, 'data.userWalletAddress', ''), 6, 4),
              commissionRate: _.get(noti, 'data.commissionRate', ''),
            }}
            components={[
              <span
                key="0"
                onClick={() => {
                  return window.open(
                    `${process.env.REACT_APP_BLOCK_EXPLORER_URL}/address/${_.get(
                      noti,
                      'data.userWalletAddress',
                      '',
                    )}`,
                    '_blank',
                    'noopener,noreferrer',
                  );
                }}
                className="event_name_link"
              ></span>,
              <span key="1" className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.ANNOUNCEMENT:
        return (
          <div className="content-noti-markdown two-line">
            <ReactMarkdown>
              {_.get(noti, `data.content[${detectLanguage(getStorageUserLanguage() ?? '')}]`, '')}
            </ReactMarkdown>
          </div>
        );

      case NOTIFICATION_TYPE.CANCEL_WITHDRAWAL:
        const withdrawalId = _.get(noti, 'data.withdrawalId', '');

        return (
          <Trans
            i18nKey="notification.withdrawRequestCanceledDesc"
            values={{
              withdrawalId,
            }}
            components={[
              <span
                key="0"
                onClick={(e) => gotoWithdrawRequest(e, withdrawalId)}
                className="event_name_link"
              ></span>,
              <span
                key="1"
                onClick={(e) => gotoWithdrawRequest(e, withdrawalId)}
                className="event_name_link"
              ></span>,
              <span key="2" onClick={gotoContactUs} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.WITHDRAW_REQUEST_SUCCEEDED: {
        const withdrawRequestId = _.get(noti, 'data.withdrawRequestId', '');
        const recipientAddress = _.get(noti, 'data.recipientAddress', '');

        return (
          <Trans
            i18nKey="notification.withdrawRequestSuccessDesc"
            values={{
              withdrawRequestId,
              recipientAddress: ellipseAddress(recipientAddress, 6, 4),
            }}
            components={[
              <span
                key="0"
                onClick={(e) => gotoWithdrawRequest(e, withdrawRequestId)}
                className="event_name_link"
              ></span>,
              <span
                key="1"
                onClick={(e) => gotoBscScanDetailAddress(e, recipientAddress)}
                className="event_name_link"
              ></span>,
            ]}
          />
        );
      }

      case NOTIFICATION_TYPE.WITHDRAW_REQUEST_UNSUCCEEDED: {
        const withdrawRequestId = _.get(noti, 'data.withdrawRequestId', '');
        const recipientAddress = _.get(noti, 'data.recipientAddress', '');

        return (
          <Trans
            i18nKey="notification.withdrawRequestUnsuccessDesc"
            values={{
              withdrawRequestId,
              recipientAddress: ellipseAddress(recipientAddress, 6, 4),
            }}
            components={[
              <span
                key="0"
                onClick={(e) => gotoWithdrawRequest(e, withdrawRequestId)}
                className="event_name_link"
              ></span>,
              <span
                key="1"
                onClick={(e) => gotoBscScanDetailAddress(e, recipientAddress)}
                className="event_name_link"
              ></span>,
              <span key="2" onClick={gotoContactUs} className="event_name_link"></span>,
            ]}
          />
        );
      }

      default:
        return '';
    }
  };

  const renderIconNotify = (notifyType: string) => {
    switch (notifyType) {
      case NOTIFICATION_TYPE.UpdateTradingLevel:
      case NOTIFICATION_TYPE.PairCreated:
      case NOTIFICATION_TYPE.PairDeleted:
        return AlertIconComponent;
      default:
        return MailIconComponent;
    }
  };

  return (
    <div className={`notification-container-mobile ${userTheme}`}>
      <div className="notification__header">
        <div className="count">{t('layout.header.notification.notificationTitle')}</div>
        <div className="mark-all" onClick={markReadAll}>
          {t('layout.header.notification.markAllAsRead')}
        </div>
      </div>
      <div className="line"></div>
      <div className="notification__content">
        {notificationData.map((item: any, index: number) => {
          return (
            <div key={item.id}>
              <div onClick={() => handleClickItem(item)}>
                <div className="notification__item">
                  <div>
                    <div className={`dot-icon ${item.is_read ? 'inactive' : 'active'}`}></div>
                  </div>
                  <Icon className="notification-icon" component={renderIconNotify(item.type)} />
                  <div className="notification-item__content">
                    <span className="title">{renderTitleNoti(item)}</span>
                    <span className="content">{renderMessageNoti(item)}</span>
                    <span className="time">
                      {/* {moment(item.created_at)
                        ?.locale(language === 'zh' ? 'zh-cn' : language)
                        .fromNow()} */}
                      {moment(item.created_at).format(formatDate)}
                    </span>
                  </div>
                </div>
              </div>
              {notificationData.length > 0 && index < notificationData.length - 1 && (
                <div className="secondary-line"></div>
              )}
            </div>
          );
        })}
      </div>
      {!isEmpty(notificationData) && (
        <div className="view-all-notification">
          <a href={PATHS_IAO.viewAllNotification()} rel="noreferrer" target="_blank">
            {t('layout.header.notification.viewAll')}
          </a>
        </div>
      )}
    </div>
  );
};

export default NotificationContent;
