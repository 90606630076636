import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router';
import { PATHS } from 'src/constants/paths';
import { hasStorageJwtToken } from 'src/helpers/storage';
import eventBus from 'src/socket/event-bus';

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const history = useHistory();

  useEffect(() => {
    const handlePrivateRouteWhenAccessTokenExpired = () => {
      history.push(PATHS.exchange());
    };

    eventBus.on(`access_token_expired`, handlePrivateRouteWhenAccessTokenExpired);

    return () => {
      eventBus.remove(`access_token_expired`, handlePrivateRouteWhenAccessTokenExpired);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLogined = hasStorageJwtToken();

  if (!isLogined) {
    return (
      <Redirect
        to={{
          pathname: PATHS.exchange(),
        }}
      />
    );
  }

  return <Route {...props} />;
};
