/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import io from 'socket.io-client';
import { getStorageJwtToken } from 'src/helpers/storage';
import { Pair } from 'src/interfaces/pair';
import { SocketEvent } from 'src/socket/SocketEvent';
import eventBus from './event-bus';

export class BaseSocket {
  private static instance: BaseSocket;
  private socket: any;

  // @ts-ignore
  private currentPair: Pair;

  public static getInstance(): BaseSocket {
    if (!BaseSocket.instance) {
      BaseSocket.instance = new BaseSocket();
    }

    return BaseSocket.instance;
  }

  public connect(): void {
    const accessToken = getStorageJwtToken();
    this.socket = io(process.env.REACT_APP_BASE_SOCKET as string, {
      transports: ['websocket'],
      query: {
        authorization: accessToken,
      },
    });
    this.socket.on('connect', () => {
      console.log('connect');
    });

    this.socket.on('disconnect', (reason: any) => {
      console.log('disconnect');
    });
    this.listen24TickerEvent();
    this.listenOrderEvent();
    this.listenTradeEvent();
    this.listenSettingTradingFee();
    this.listenDeletePair();
    this.listenNotificationEvent();
    this.listenCancelOrderEvent();
  }

  public reconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.connect();

    if (this.currentPair) {
      this.listenPairEvents(this.currentPair);
    }
  }

  listenTradeBars(pair_id: any) {
    this.socket.on(`${SocketEvent.Trades_PairId + pair_id}`, (data: any) => {
      eventBus.dispatch(`${SocketEvent.Trades_PairId + pair_id}`, data);
    });
  }

  listenPairEvents(pair?: any): void {
    if (this.currentPair) {
      this.socket.off(`trades_${this.currentPair.pairs_id}`);
      this.socket.off(`orderbook_${this.currentPair.pairs_id}`);
      this.socket.off(`stellar_orderbook_${this.currentPair.pairs_id}`);
      this.socket.off(`swaps_${this.currentPair.pairs_id}`);
    }
    this.currentPair = pair;
    if (!this.currentPair) {
      return;
    }

    this.socket.on(`trades_${this.currentPair.pairs_id}`, (data: any) => {
      eventBus.dispatch(SocketEvent.OrderbookTradeCreated, data);
      eventBus.dispatch(SocketEvent.PoolTradeCreated, data);
      eventBus.dispatch(SocketEvent.SwapCreated, data);
    });

    this.socket.on(`orderbook_${this.currentPair.pairs_id}`, (data: any) => {
      eventBus.dispatch(`orderbook_${this.currentPair.pairs_id}`, data);
    });

    this.socket.on(`stellar_orderbook_${this.currentPair.pairs_id}`, (data: any) => {
      eventBus.dispatch(SocketEvent.StellarOrderbookUpdated, data);
    });

    this.socket.on(`swaps_${this.currentPair.pairs_id}`, (data: any) => {});
  }

  listen24TickerEvent(): void {
    this.socket.on(SocketEvent.Ticker24h, (data: any) => {
      eventBus.dispatch(SocketEvent.Ticker24h, data);
    });
  }

  listenOrderEvent(): void {
    this.socket.on(SocketEvent.Orders, (data: any) => {
      eventBus.dispatch(SocketEvent.Orders, data);
    });
  }

  listenTradeEvent(): void {
    this.socket.on(SocketEvent.Trades_Updated, (data: any) => {
      eventBus.dispatch(SocketEvent.Trades_Updated, data);
    });
  }

  disconnectSocket(): void {
    this.socket.disconnect();
  }

  listenSettingTradingFee(): void {
    this.socket.on(SocketEvent.TradingFee, (data: any) => {
      eventBus.dispatch(SocketEvent.TradingFeeUpdated, {});
    });
  }

  listenDeletePair(): void {
    this.socket.on(SocketEvent.delete_pair, (pairId: number) => {
      eventBus.dispatch(SocketEvent.delete_pair, pairId);
    });
  }

  listenNotificationEvent(): void {
    this.socket.on(SocketEvent.Notification, (data: any) => {
      eventBus.dispatch(SocketEvent.Notification, data);
    });
  }

  listenCancelOrderEvent(): void {
    this.socket.on(SocketEvent.CancelOrder, (data: any) => {
      eventBus.dispatch(SocketEvent.CancelOrder, data);
    });
  }
}
