import jwt_decode from 'jwt-decode';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fracToast2 } from 'src/components/07.toast';
import { ERRORS } from 'src/constants/messages';
import { getStorageJwtToken, hasStorageJwtToken, removeStorageJwtToken } from 'src/helpers/storage';
import { useLogin } from 'src/hooks/useLogin';
import eventBus from 'src/socket/event-bus';
import { clearAllBalances } from 'src/store/actions/balances';
import { setAccessTokenRedux } from 'src/store/actions/user';
import { ConnectorKey } from 'src/web3/connectors';
import { useActiveWeb3React, useConnectWallet, useEagerConnect } from 'src/web3/hooks';

type JWT_DECODE = {
  exp: number;
  iat: number;
  role: number;
  sub: number;
};

export const authContext = React.createContext<
  | {
      isAuthChecking: boolean;
      isAuth: boolean;
      signIn: (connector: ConnectorKey) => Promise<void>;
      signInWalletConnect: (connector: ConnectorKey) => Promise<void>;
      signOut: VoidFunction;
      hasSigned: VoidFunction;
    }
  | undefined
>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { active } = useActiveWeb3React();
  const { disconnectWallet } = useConnectWallet();
  const triedEagerConnect = useEagerConnect();
  const { userLogin } = useLogin();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [userLogined, setUserLogined] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(handleExpireDate, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  function handleExpireDate() {
    const isLogined = hasStorageJwtToken();
    let isExpired = false;
    if (isLogined) {
      const decoded = jwt_decode<JWT_DECODE>(getStorageJwtToken() as string);
      const expiredTimeServer = moment(decoded?.exp * 1000);
      isExpired = moment().isAfter(expiredTimeServer);
      if (isExpired) {
        removeStorageJwtToken();
        dispatch(setAccessTokenRedux(''));
      }
      setUserLogined(isExpired ? false : true);
    } else {
      setUserLogined(isLogined);
    }
  }

  async function signIn(connectorKey: ConnectorKey) {
    // Ignore signing message
    // if (!hasStorageJwtToken()) {
    await userLogin(connectorKey);
    setUserLogined(true);
    // }
  }

  async function signInWalletConnect(connectorKey: ConnectorKey) {
    // if (!hasStorageJwtToken()) {
    await userLogin(connectorKey);
    setUserLogined(true);
    // }
  }

  function signOut() {
    removeStorageJwtToken();
    disconnectWallet();
  }

  function hasSigned() {
    handleExpireDate();
    setUserLogined(true);
  }

  useEffect(() => {
    const handleWalletWhenAccessTokenExpired = debounce(() => {
      if (hasStorageJwtToken()) {
        fracToast2.error('', t(ERRORS.SESSION_EXPIRED));
      }
      signOut();
      dispatch(clearAllBalances());
    }, 2000);

    eventBus.on(`access_token_expired`, handleWalletWhenAccessTokenExpired);

    return () => {
      eventBus.remove(`access_token_expired`, handleWalletWhenAccessTokenExpired);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <authContext.Provider
      value={{
        isAuthChecking: !triedEagerConnect,
        isAuth: userLogined,
        signIn,
        signInWalletConnect,
        signOut,
        hasSigned,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
