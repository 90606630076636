import moment from 'moment';
import { DateRangeOption, FilterType } from 'src/types/profit-and-lost';

export const DEFAULT_MODAL_WIDTH = 528; // unit pixcel
export const METHOD_IS_ORDERBOOK = 2;

//Profit and loss

export const DATE_RANGE: DateRangeOption[] = [
  {
    id: FilterType.Last_7_DAYS,
    label: 'Last 7 days',
    value: moment().subtract(7, 'days').unix(),
  },
  {
    id: FilterType.Last_30_DAYS,
    label: 'Last 30 days',
    value: moment().subtract(30, 'days').unix(),
  },
  {
    id: FilterType.CUSTOM_RANGE,
    label: 'Custom range',
    value: moment().subtract(30, 'days').unix(),
  },
];

export const WALLET_ANALYTICS_QUERY_PARAMS = {
  OVERVIEW: 'overview',
  BALANCES: 'balances',
  PROFIT_AND_LOSS: 'profit-and-loss',
};

export type BooleanBE = 0 | 1;

export const NOTIFICATION_TYPE = {
  //notification DEX
  UpdateTradingLevel: 'UpdateTradingLevel',
  PairCreated: 'PairCreated',
  PairDeleted: 'PairDeleted',
  UserTierChanged: 'UserTierChanged',
  // notification IAO
  WHITELISTS: 'Whitelists',
  IAO_PARTICIPATION_START: 'IaoParticipationStart',
  IAO_VAULT_SUCCEEDED: 'IaoVaultSuccess',
  IAO_VAULT_FAILED: 'IaoVaultFailed',
  IAO_NON_VAULT_SUCCEEDED: 'IaoNonVaultSuccess',
  REJECT_IAO_REVENUE: 'RejectIaoRevenue',
  REDEMPTION_REQUEST_APPROVAL: 'RedemptionRequestApproval',
  REDEMPTION_REQUEST_REJECT: 'RedemptionRequestReject',
  NEW_AFFILIATE_OFFER: 'NewAffiliateOffer',
  ACCEPT_AFFILIATE_OFFER: 'AcceptAffiliateOffer',
  SECOND_APPROVED_IAO_REQUEST: 'SecondApprovedIAORequest',
  CREATE_IAO_EVENT_PUBLIC: 'CreateIAOEventPublic',
  REJECT_IAO_REQUEST: 'RejectIAORequest',
  APPROVE_IAO_REVENUE: 'ApproveIAORevenue',
  ANNOUNCEMENT: 'Announcement',
  CANCEL_WITHDRAWAL: 'CancelWithdrawal',
  WITHDRAW_REQUEST_SUCCEEDED: 'WithdrawRequestSucceeded',
  WITHDRAW_REQUEST_UNSUCCEEDED: 'WithdrawRequestUnsucceeded',
};

export const FORMAT_DATE_NOTIFY = 'DD-MM-YYYY hh:mm:ss (UTC Z)';

export const WALLET_IS_ADMIN_ERROR_CODE = 'E28';

export const WALLET_IS_DEACTIVATED_ERROR_CODE = 'E29';

export const PAIR_TYPE = {
  FLEXIBLE: 'FLEXIBLE',
  WHOLE_LOT: 'WHOLE_LOT',
};
