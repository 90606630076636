import { ServiceBase } from './core/service-base';
import {
  BalanceInOrderParams,
  CreateOrderParams,
  ListOrderBookParams,
  ListOrderParams
} from './params-type';

export class OrderService extends ServiceBase {
  getListOrderBook = (params: ListOrderBookParams) => {
    return this.get('/orderbook', params);
  };

  createOrder = (params: CreateOrderParams) => {
    return this.post('/order', params);
  };

  getListOrders = (params: ListOrderParams) => {
    return this.get('/order/list', params);
  };

  cancelOrder = (id: number) => {
    return this.put(`/order/${id}/cancel`, {});
  };

  getExchangeRate = () => {
    return this.get(`/misc/exchange-rates`);
  };

  getBalanceInOrder = (params: BalanceInOrderParams) => {
    return this.get(`/order/balances-in-order`, params);
  };

  getVolatility = () => {
    return this.get(`users/volatility`);
  };

  getConfidenceInterval = () => {
    return this.get(`/users/confidence`);
  };

  getIntervalSettings = (params: any) => {
    return this.get('/users/get-interval-settings', params);
  };
}
