const GET_LIST_TRADES = 'GET_LIST_TRADES';
const SET_INTERVAL_TRADE = 'SET_INTERVAL_TRADE';
const SET_IMPORTING_TOKEN_LIST = 'SET_IMPORTING_TOKEN_LIST';
const CLEAR_IMPORTING_TOKEN_LIST = 'CLEAR_IMPORTING_TOKEN_LIST';

export const trade = {
  GET_LIST_TRADES,
  SET_INTERVAL_TRADE,
  SET_IMPORTING_TOKEN_LIST,
  CLEAR_IMPORTING_TOKEN_LIST,
};
