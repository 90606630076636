import FracModal from '.';
import React from 'react';
import { ModalProps } from 'antd';
import './styles/deactivated-modal.scss';

import { Trans, useTranslation } from 'react-i18next';
import { DeactivatedIcon } from 'src/assets/icons';
import { useSelector } from 'react-redux';
import { PATHS_IAO } from 'src/constants/paths';
import { MESSAGES } from 'src/constants/messages';

interface IDeactivatedModal extends ModalProps {}

const DeactivatedModal: React.FC<IDeactivatedModal> = (props) => {
  const { t } = useTranslation();
  const theme = useSelector((state: any) => state.theme);

  const gotoContactUs = (e: React.MouseEvent) => {
    e.stopPropagation();

    return window.open(`${PATHS_IAO.aboutUs()}`, '_blank', 'noopener,noreferrer');
  };
  return (
    <FracModal {...props} title={<div style={{ textAlign: 'center' }}>{t(MESSAGES.MC56)}</div>}>
      <div className={`wrap ${theme}`}>
        <img src={DeactivatedIcon} alt="" className={'image'} />
        <div className={'description'}>
          <Trans
            i18nKey={t(MESSAGES.MC57)}
            components={[
              <span key="0" onClick={gotoContactUs} className="description__link"></span>,
            ]}
          />
        </div>
      </div>
    </FracModal>
  );
};

export default DeactivatedModal;
