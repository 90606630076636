import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FracModal from '.';
import ButtonContained from '../02.buttons/ButtonContained';
import './styles/disconnect-wallet-modal.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DisconnectWalletModal: React.FC<Props> = ({ isOpen, onClose, onConfirm }: Props) => {
  const { t } = useTranslation();
  const userTheme = useSelector((state: any) => state.theme);

  return (
    <FracModal
      open={isOpen}
      onCancel={onClose}
      wrapClassName={`disconnect-wallet-modal-${userTheme}`}
    >
      <div className="modal-disconnect">
        <div className="modal-disconnect__content">
          <div className="modal-disconnect__content__title">
            <span>{t('layout.header.profileDetail.disconnectWallet')}</span>
          </div>
          <span>{t('layout.header.profileDetail.disconnectNote1')}</span>
          <span>{t('layout.header.profileDetail.disconnectNote2')}</span>
        </div>
        <ButtonContained className="button-confirm" onClick={onConfirm}>
          {t('layout.header.profileDetail.disconnectConfirm')}
        </ButtonContained>
      </div>
    </FracModal>
  );
};

export default DisconnectWalletModal;
