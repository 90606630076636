import BigNumber from 'bignumber.js';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import FracModal from 'src/components/06.modals';
import { FORMAT_DATE_NOTIFY, NOTIFICATION_TYPE } from 'src/constants';
import { PATHS_IAO } from 'src/constants/paths';
import { ellipseAddress } from 'src/helpers';
import { getStorageUserLanguage } from 'src/helpers/storage';
import { formatDate } from '../..';
import { detectLanguage } from '../NotificationContent';
import './style.scss';
import { REACT_APP_CHAIN_ID, REACT_APP_NETWORK_NAME } from 'src/web3/constants/envs';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  notifyDetail: any;
}

const ModalNotificationDetail: React.FC<Props> = ({ isOpen, onClose, notifyDetail }: Props) => {
  const userTheme = useSelector((state: any) => state.theme);
  const { t } = useTranslation();

  const renderTitleNoti = (noti: any) => {
    switch (noti.type) {
      case NOTIFICATION_TYPE.UpdateTradingLevel:
        return t('notification.type.updateTradingFee');

      case NOTIFICATION_TYPE.PairCreated:
        return t('notification.type.created');

      case NOTIFICATION_TYPE.PairDeleted:
        return t('notification.type.deleted', {
          pairName: `${noti.data.baseSymbol}/${noti.data.quoteSymbol}`,
        });

      case NOTIFICATION_TYPE.UserTierChanged:
        return t('notification.type.userTierChanged', {
          pairName: `${noti.data.baseSymbol}/${noti.data.quoteSymbol}`,
        });

      case NOTIFICATION_TYPE.WHITELISTS:
        return t('notification.type.whiteLits');

      case NOTIFICATION_TYPE.IAO_PARTICIPATION_START:
        return t('notification.type.iaoParticipationStart');

      case NOTIFICATION_TYPE.IAO_VAULT_FAILED:
        return t('notification.type.iaoVaultFailed');

      case NOTIFICATION_TYPE.IAO_VAULT_SUCCEEDED:
        return t('notification.type.iaoVaultSuccess');

      case NOTIFICATION_TYPE.IAO_NON_VAULT_SUCCEEDED:
        return t('notification.type.iaoNonVaultSuccess');

      case NOTIFICATION_TYPE.REJECT_IAO_REVENUE:
        return t('notification.iaoResultRejectedTitle');

      case NOTIFICATION_TYPE.REDEMPTION_REQUEST_APPROVAL:
        return t('notification.redemptionRequestApprovedTitle');

      case NOTIFICATION_TYPE.REDEMPTION_REQUEST_REJECT:
        return t('notification.redemptionRequestRejectedTitle');

      case NOTIFICATION_TYPE.NEW_AFFILIATE_OFFER:
        return t('notification.newAffiliateOfferTitle');

      case NOTIFICATION_TYPE.ACCEPT_AFFILIATE_OFFER:
        return t('notification.afffiliateOfferAcceptedTitle');

      case NOTIFICATION_TYPE.ANNOUNCEMENT:
        return _.get(noti, `data.title[${detectLanguage(getStorageUserLanguage() ?? '')}]`, '');

      case NOTIFICATION_TYPE.CANCEL_WITHDRAWAL:
        return t('notification.withdrawRequestCanceledTitle');

      case NOTIFICATION_TYPE.WITHDRAW_REQUEST_SUCCEEDED:
        return t('notification.withdrawRequestSuccessTitle');

      case NOTIFICATION_TYPE.WITHDRAW_REQUEST_UNSUCCEEDED:
        return t('notification.withdrawRequestUnsuccessTitle');

      default:
        return '';
    }
  };

  const renderMessageNoti = (noti: any) => {
    const gotoIaoEventDetail = (e: React.MouseEvent) => {
      e.stopPropagation();

      return window.open(
        `${PATHS_IAO.viewAll()}/${_.get(noti, 'data.iaoEventId', '')}`,
        '_blank',
        'noopener,noreferrer',
      );
    };

    const gotoRedempDetail = (e: React.MouseEvent) => {
      e.stopPropagation();

      return window.open(
        `${PATHS_IAO.assetRedemption()}?requestId=${_.get(noti, 'data.redempId', '')}`,
        '_blank',
        'noopener,noreferrer',
      );
    };

    const gotoContactUs = (e: React.MouseEvent) => {
      e.stopPropagation();

      return window.open(`${PATHS_IAO.aboutUs()}`, '_blank', 'noopener,noreferrer');
    };

    const gotoMyProfile = (e: React.MouseEvent) => {
      e.stopPropagation();

      return window.open(`${PATHS_IAO.myProfile()}`, '_blank', 'noopener,noreferrer');
    };

    const gotoWithdrawRequest = (e: React.MouseEvent, withdrawalId: number) => {
      e.stopPropagation();

      return window.open(
        `${PATHS_IAO.withdrawRequest()}/${withdrawalId}`,
        '_blank',
        'noopener,noreferrer',
      );
    };

    const gotoBscScanDetailAddress = (e: React.MouseEvent, address: string) => {
      e.stopPropagation();

      return window.open(
        `${process.env.REACT_APP_BLOCK_EXPLORER_URL}/address/${address}`,
        '_blank',
        'noopener,noreferrer',
      );
    };

    switch (noti.type) {
      case NOTIFICATION_TYPE.UpdateTradingLevel:
        return t('notification.message.updateTradingFee', {
          feeType: noti.data.feeType === 1 ? 'Taker fee' : 'Maker fee',
          network: noti.data.network === REACT_APP_CHAIN_ID ? REACT_APP_NETWORK_NAME : '',
          tierName: noti.data.tierName,
          oldValue: new BigNumber(noti.data.oldValue).multipliedBy(100).toNumber(),
          newValue: new BigNumber(noti.data.newValue).multipliedBy(100).toNumber(),
          date: moment(noti.data.createdAt * 1000).format(FORMAT_DATE_NOTIFY),
        });

      case NOTIFICATION_TYPE.PairCreated:
        return t('notification.message.created', {
          pairName: `${noti.data.baseSymbol}/${noti.data.quoteSymbol}`,
          date: moment(noti.data.createdAt * 1000).format(FORMAT_DATE_NOTIFY),
        });

      case NOTIFICATION_TYPE.PairDeleted:
        return t('notification.message.deleted', {
          pairName: `${noti.data.baseSymbol}/${noti.data.quoteSymbol}`,
          date: moment(noti.data.deletedAt * 1000).format(FORMAT_DATE_NOTIFY),
        });

      case NOTIFICATION_TYPE.UserTierChanged:
        return t('notification.message.userTierChanged', {
          oldTier: noti.data.oldTier,
          newTier: noti.data.newTier,
          date: moment(noti.data.createdAt * 1000).format(FORMAT_DATE_NOTIFY),
        });

      case NOTIFICATION_TYPE.WHITELISTS:
        // return t('notification.message.whiteLits', {
        //   eventName: _.get(noti, 'data.iaoEventName.en', ''),
        // });

        return (
          <Trans
            i18nKey="notification.message.whiteLits"
            values={{
              eventName: _.get(noti, 'data.iaoEventName.en', ''),
            }}
            components={[
              <span key="0" onClick={gotoIaoEventDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.IAO_PARTICIPATION_START:
        // return t('notification.message.iaoParticipationStart', {
        //   eventName: _.get(noti, 'data.iaoEventName.en', ''),
        // });

        return (
          <Trans
            i18nKey="notification.message.iaoParticipationStart"
            values={{
              eventName: _.get(noti, 'data.iaoEventName.en', ''),
            }}
            components={[
              <span key="0" onClick={gotoIaoEventDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.IAO_VAULT_FAILED:
        // return t('notification.message.iaoVaultFailed', {
        //   eventName: _.get(noti, 'data.iaoEventName.en', ''),
        // });

        return (
          <Trans
            i18nKey="notification.message.iaoVaultFailed"
            values={{
              eventName: _.get(noti, 'data.iaoEventName.en', ''),
            }}
            components={[
              <span key="0" onClick={gotoIaoEventDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.IAO_VAULT_SUCCEEDED:
        // return t('notification.message.iaoVaultSuccess', {
        //   eventName: _.get(noti, 'data.iaoEventName.en', ''),
        // });

        return (
          <Trans
            i18nKey="notification.message.iaoVaultSuccess"
            values={{
              eventName: _.get(noti, 'data.iaoEventName.en', ''),
            }}
            components={[
              <span key="0" onClick={gotoIaoEventDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.IAO_NON_VAULT_SUCCEEDED:
        // return t('notification.message.iaoNonVaultSuccess', {
        //   eventName: _.get(noti, 'data.iaoEventName.en', ''),
        // });

        return (
          <Trans
            i18nKey="notification.message.iaoNonVaultSuccess"
            values={{
              eventName: _.get(noti, 'data.iaoEventName.en', ''),
            }}
            components={[
              <span key="0" onClick={gotoIaoEventDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.REJECT_IAO_REVENUE:
        // return t('notification.iaoResultRejectedDes', {
        //   eventName: _.get(noti, 'data.iaoEventName.en', ''),
        // });

        return (
          <Trans
            i18nKey="notification.iaoResultRejectedDes"
            values={{
              eventName: _.get(noti, 'data.iaoEventName.en', ''),
            }}
            components={[
              <span key="0" onClick={gotoIaoEventDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.REDEMPTION_REQUEST_APPROVAL:
        // return t('notification.redemptionRequestApprovedDesc', {
        //   redempId: _.get(noti, 'data.redempId', ''),
        // });

        return (
          <Trans
            i18nKey="notification.redemptionRequestApprovedDesc"
            values={{
              redempId: _.get(noti, 'data.redempId', ''),
            }}
            components={[
              <span key="0" onClick={gotoRedempDetail} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.REDEMPTION_REQUEST_REJECT:
        // return t('notification.redemptionRequestRejectedDesc', {
        //   redempId: _.get(noti, 'data.redempId', ''),
        // });

        return (
          <Trans
            i18nKey="notification.redemptionRequestRejectedDesc"
            values={{
              redempId: _.get(noti, 'data.redempId', ''),
            }}
            components={[
              <span key="0" onClick={gotoRedempDetail} className="event_name_link"></span>,
              <span key="1" onClick={gotoContactUs} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.NEW_AFFILIATE_OFFER:
        // return t('notification.newAffiliateOfferDesc');

        return (
          <Trans
            i18nKey="notification.newAffiliateOfferDesc"
            components={[<span key="0" onClick={gotoMyProfile} className="event_name_link"></span>]}
          />
        );

      case NOTIFICATION_TYPE.ACCEPT_AFFILIATE_OFFER:
        // return t('notification.afffiliateOfferAcceptedDesc', {
        //   walletAddress: _.get(noti, 'data.userWalletAddress', ''),
        //   commissionRate: _.get(noti, 'data.commissionRate', ''),
        // });

        return (
          <Trans
            i18nKey="notification.afffiliateOfferAcceptedDesc"
            values={{
              walletAddress: ellipseAddress(_.get(noti, 'data.userWalletAddress', ''), 6, 4),
              commissionRate: _.get(noti, 'data.commissionRate', ''),
            }}
            components={[
              <span
                key="0"
                onClick={() => {
                  return window.open(
                    `${process.env.REACT_APP_BLOCK_EXPLORER_URL}/address/${_.get(
                      noti,
                      'data.userWalletAddress',
                      '',
                    )}`,
                    '_blank',
                    'noopener,noreferrer',
                  );
                }}
                className="event_name_link"
              ></span>,
              <span key="1" className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.ANNOUNCEMENT:
        return (
          <div>
            <ReactMarkdown>
              {_.get(noti, `data.content[${detectLanguage(getStorageUserLanguage() ?? '')}]`, '')}
            </ReactMarkdown>
          </div>
        );

      case NOTIFICATION_TYPE.CANCEL_WITHDRAWAL:
        const withdrawalId = _.get(noti, 'data.withdrawalId', '');

        return (
          <Trans
            i18nKey="notification.withdrawRequestCanceledDesc"
            values={{
              withdrawalId,
            }}
            components={[
              <span
                key="0"
                onClick={(e) => gotoWithdrawRequest(e, withdrawalId)}
                className="event_name_link"
              ></span>,
              <span
                key="1"
                onClick={(e) => gotoWithdrawRequest(e, withdrawalId)}
                className="event_name_link"
              ></span>,
              <span key="2" onClick={gotoContactUs} className="event_name_link"></span>,
            ]}
          />
        );

      case NOTIFICATION_TYPE.WITHDRAW_REQUEST_SUCCEEDED: {
        const withdrawRequestId = _.get(noti, 'data.withdrawRequestId', '');
        const recipientAddress = _.get(noti, 'data.recipientAddress', '');

        return (
          <Trans
            i18nKey="notification.withdrawRequestSuccessDesc"
            values={{
              withdrawRequestId,
              recipientAddress: ellipseAddress(recipientAddress, 6, 4),
            }}
            components={[
              <span
                key="0"
                onClick={(e) => gotoWithdrawRequest(e, withdrawRequestId)}
                className="event_name_link"
              ></span>,
              <span
                key="1"
                onClick={(e) => gotoBscScanDetailAddress(e, recipientAddress)}
                className="event_name_link"
              ></span>,
            ]}
          />
        );
      }

      case NOTIFICATION_TYPE.WITHDRAW_REQUEST_UNSUCCEEDED: {
        const withdrawRequestId = _.get(noti, 'data.withdrawRequestId', '');
        const recipientAddress = _.get(noti, 'data.recipientAddress', '');

        return (
          <Trans
            i18nKey="notification.withdrawRequestUnsuccessDesc"
            values={{
              withdrawRequestId,
              recipientAddress: ellipseAddress(recipientAddress, 6, 4),
            }}
            components={[
              <span
                key="0"
                onClick={(e) => gotoWithdrawRequest(e, withdrawRequestId)}
                className="event_name_link"
              ></span>,
              <span
                key="1"
                onClick={(e) => gotoBscScanDetailAddress(e, recipientAddress)}
                className="event_name_link"
              ></span>,
              <span key="2" onClick={gotoContactUs} className="event_name_link"></span>,
            ]}
          />
        );
      }

      default:
        return '';
    }
  };

  return (
    <FracModal open={isOpen} onCancel={onClose} wrapClassName={`modal-notify-wrapper-${userTheme}`}>
      <div className={`modal-notify ${userTheme}`}>
        <div className="modal-notify-title">
          {t('layout.header.notification.notificationModalTitle')}
        </div>
        <div className="modal-notify-detail">
          <div className="modal-notify-detail__title">{renderTitleNoti(notifyDetail)}</div>
          <div className="modal-notify-detail__date">
            {moment(notifyDetail?.created_at).format(formatDate)}
          </div>
          <div className="modal-notify-detail__message">{renderMessageNoti(notifyDetail)}</div>
        </div>
      </div>
    </FracModal>
  );
};

export default ModalNotificationDetail;
