import { ServiceBase } from './core/service-base';
import { BodyMarkRead, IListNotifications } from './params-type';

export class NotificationService extends ServiceBase {
  getCountNotRead = () => {
    return this.get('/notification/count-not-read');
  };

  getListNotifications = (params: IListNotifications) => {
    return this.get('/notification', params);
  };

  asRead = (body?: BodyMarkRead) => {
    return this.put('/notification/read', body);
  };
}
