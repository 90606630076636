import { CLEAR_ORDER_BOOK } from './../constants/order-book';
import BigNumber from 'bignumber.js';
import _, { get } from 'lodash';
import { OrderBookLength, OrderSide } from 'src/constants/exchange';
import { IOrderBook } from 'src/services/params-type';
import {
  INIT_ORDER_BOOK,
  UPDATE_ORDER_BOOK,
  UPDATE_PREV_ASK_PRICE,
} from 'src/store/constants/order-book';

const INIT_VALUE = new BigNumber(0);

const initialState = {
  asks: [],
  bids: [],
  maxTotalAsk: INIT_VALUE,
  maxTotalBid: INIT_VALUE,
  askPrice: '0',
  bidPrice: '0',
  prevAsk: '0',
};

let asksMap: Map<string, string> = new Map();
let bidsMap: Map<string, string> = new Map();

export const orderBookReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INIT_ORDER_BOOK:
      const asks = [...get(action, 'payload.asks', [])].map(({ price, amount }: any) => ({
        price: new BigNumber(price).toString(),
        amount: new BigNumber(amount).toString(),
      }));
      const bids = [...get(action, 'payload.bids', [])];

      let maxTotalAsk = INIT_VALUE;
      let maxTotalBid = INIT_VALUE;

      asks.forEach((item: IOrderBook) => {
        const total = new BigNumber(item.price).times(new BigNumber(item.amount));
        if (total.gte(maxTotalAsk)) {
          maxTotalAsk = total;
        }
        asksMap.set(new BigNumber(item.price).toString(), item.amount);
      });
      bids.forEach((item: IOrderBook) => {
        const total = new BigNumber(item.price).times(new BigNumber(item.amount));
        if (total.gte(maxTotalBid)) {
          maxTotalBid = total;
        }
        bidsMap.set(new BigNumber(item.price).toString(), item.amount);
      });

      return {
        asks: asks?.slice(0, OrderBookLength.type2),
        bids: bids?.slice(0, OrderBookLength.type2),
        maxTotalBid: maxTotalBid?.toString(),
        maxTotalAsk: maxTotalAsk?.toString(),
        ask: new BigNumber(asks[0]?.price).toString(),
        bid: new BigNumber(bids[0]?.price).toString(),
        prevAsk: new BigNumber(asks[0]?.price).toString(),
      };

    case UPDATE_ORDER_BOOK:
      let maxTotalAskStore = new BigNumber(state.maxTotalAsk);
      let maxTotalBidStore = new BigNumber(state.maxTotalBid);

      const socketData = get(action, 'payload', []);

      const prevAskMap = asksMap;

      const sortedPrevAskMap = Array.from(prevAskMap, ([price, amount]) => ({
        price,
        amount,
      })).sort((a: any, b: any) => a.price - b.price);

      socketData.forEach((order: any) => {
        const { price, side, amount } = order;
        const formatPrice = new BigNumber(price).toString();
        let newAmount = amount;
        if (side === OrderSide.Buy) {
          if (bidsMap.has(formatPrice)) {
            newAmount = new BigNumber(bidsMap.get(formatPrice) as string).plus(amount).toString();
            if (new BigNumber(newAmount).gt(0)) {
              bidsMap.set(new BigNumber(formatPrice).toString(), newAmount);
            } else {
              bidsMap.delete(formatPrice);
            }
          } else {
            bidsMap.set(formatPrice, amount);
          }
          const total = new BigNumber(formatPrice).times(new BigNumber(newAmount));
          if (total.gte(maxTotalBidStore)) {
            maxTotalBidStore = total;
          }
        } else {
          if (asksMap.has(formatPrice)) {
            const newAmount = new BigNumber(asksMap.get(formatPrice) as string)
              .plus(amount)
              .toString();
            if (new BigNumber(newAmount).gt(0)) {
              asksMap.set(new BigNumber(formatPrice).toString(), newAmount);
            } else {
              asksMap.delete(formatPrice);
            }
          } else {
            asksMap.set(new BigNumber(formatPrice).toString(), amount);
          }
          const total = new BigNumber(formatPrice).times(new BigNumber(newAmount));
          if (total.gte(maxTotalBidStore)) {
            maxTotalAskStore = total;
          }
        }
      });

      const bidsSorted: Array<IOrderBook> = Array.from(bidsMap, ([price, amount]) => ({
        price,
        amount,
      })).sort((a: any, b: any) => b.price - a.price);

      const asksSorted = Array.from(asksMap, ([price, amount]) => ({
        price,
        amount,
      })).sort((a: any, b: any) => a.price - b.price);

      const bidsDisplay = bidsSorted.slice(0, OrderBookLength.type2).map((item: IOrderBook) => ({
        ...item,
        total: new BigNumber(item.price).multipliedBy(item.amount).toString(),
      }));

      const asksDisplay = asksSorted.slice(0, OrderBookLength.type2).map((item: IOrderBook) => ({
        ...item,
        total: new BigNumber(item.price).multipliedBy(item.amount).toString(),
      }));

      const prevAsk = new BigNumber(_.get(sortedPrevAskMap, '[0].price', '0'));

      return {
        bids: bidsDisplay,
        asks: asksDisplay,
        maxTotalBid: maxTotalBidStore?.toString(),
        maxTotalAsk: maxTotalAskStore?.toString(),
        ask: new BigNumber(asksDisplay[0]?.price).toString(),
        bid: new BigNumber(bidsDisplay[0]?.price).toString(),
        prevAsk: prevAsk.gt(asksDisplay[0]?.price) ? asksDisplay[0]?.price : prevAsk,
      };

    case CLEAR_ORDER_BOOK:
      asksMap = new Map();
      bidsMap = new Map();
      return {
        asks: [],
        bids: [],
        maxTotalBid: INIT_VALUE,
        maxTotalAsk: INIT_VALUE,
        askPrice: '0',
        bidPrice: '0',
        prevAsk: '0',
      };

    case UPDATE_PREV_ASK_PRICE:
      return {
        ...state,
        prevAsk: get(action, 'payload.ask', ''),
      };

    default:
      return state;
  }
};
