import Icon from '@ant-design/icons';
import { Badge, Dropdown, Layout } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  DarkThemeIcon,
  FracLogoIconVer2,
  FracLogoIconDarkVer2,
  LightThemeIcon,
  NetworkIcon,
} from 'src/assets/icons';
import {
  AlertIconComponent,
  ProfileIconComponent,
  WalletIconComponent,
} from 'src/assets/icons/IconComponent';
import MenuIcon from 'src/assets/icons/navbar/MenuIcon';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import ConnectWalletModal from 'src/components/06.modals/ConnectWalletModal';
import DeactivatedModal from 'src/components/06.modals/DeactivatedModal';
import { FracSelectWithLanguage } from 'src/components/11.select';
import { PATHS } from 'src/constants/paths';
import { setStorageLanguage, setStorageTheme } from 'src/helpers/storage';
import { useAuth } from 'src/hooks/useAuth';
import useWindowSize from 'src/hooks/useWindowSize';
import i18n from 'src/i18n/i18n';
import { THEME_MODE } from 'src/interfaces/theme';
import { TierService } from 'src/services/tier-service';
import { setTheme } from 'src/store/actions/theme';
import { setStoreMyTier } from 'src/store/actions/user';
import { pickTierColor } from './constants';
import ModalNotificationDetail from './ModalNotificationDetail';
import NotificationContent from './NotificationContent';
import ProfileDetail from './ProfileDetail';
import { languages, LANGUAGE_CODE } from './ProfileDetail/MultiLanguageContent';
import './styles.scss';
import SubMenu from './SubMenu';
import WalletDetail from './WalletDetail';
import { REACT_APP_NETWORK_NAME } from 'src/web3/constants/envs';

const { Header } = Layout;

const badgeCountClassName = (count: number) => {
  if (count < 10) return 'count-small';
  if (count >= 10 && count < 100) return 'count-medium';
  if (count >= 100) return 'count-large';
};

interface Props {
  isOpenMenuMobile: boolean;
  setIsOpenMenuMobile: (bool: boolean) => void;
  isOpenNotificationMobile: boolean;
  setIsOpenNotificationMobile: (bool: boolean) => void;
  getCountUnRead: () => void;
  notificationCount: number;
}

const AppHeader: React.FC<Props> = ({
  isOpenMenuMobile,
  setIsOpenMenuMobile,
  isOpenNotificationMobile,
  setIsOpenNotificationMobile,
  getCountUnRead,
  notificationCount,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { isAuth } = useAuth();
  const account = useSelector((state: any) => state.currentAccount);
  const userTheme = useSelector((state: any) => state.theme);
  const networkIsWrong = useSelector((state: any) => state.networkIsWrong);
  const { isDesktop, width } = useWindowSize();

  // const [notificationCount, setNotificationCount] = useState<number>(300);
  const [isOpenConnectWalletModal, setIsOpenConnectWalletModal] = useState(false);
  const [isOpenDeactivatedModal, setIsOpenDeactivatedModal] = useState(false);

  // const [language, setLanguage] = useState<any>(
  //   renderLang(i18n.resolvedLanguage) || LANGUAGE_CODE.ENGLISH,
  // );

  const { myTier } = useSelector((state: any) => ({
    myTier: get(state, 'user.myTier', ''),
  }));
  const [isOpenNotify, setIsOpenNotify] = useState(false);
  const dispatch = useDispatch();

  const userLoginSuccess = useMemo(() => {
    return isAuth && !!account;
  }, [isAuth, account]);

  const [notifyDetail, setNotifyDetail] = useState<any>();

  // const getCountUnRead = useCallback(async () => {
  //   const notifySv = new NotificationService();
  //   const data = await notifySv.getCountNotRead();
  //   console.log(data, ' DATA UNREAD');
  //   setNotificationCount(get(data, 'data', 0));
  // }, []);

  const fetchMyTierList = async () => {
    try {
      const instance = new TierService();
      const { data } = await instance.getMyTierList();
      // setMyTier(data?.current_tier);
      dispatch(setStoreMyTier(data?.current_tier));
    } catch (error) {}
  };

  useEffect(() => {
    if (userLoginSuccess && account) {
      getCountUnRead();
    }
    // eventBus.on(SocketEvent.delete_pair, () => {
    //   getCountUnRead();
    // });

    // eslint-disable-next-line
  }, [userLoginSuccess, account]);

  useEffect(() => {
    if (userLoginSuccess && account) {
      fetchMyTierList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoginSuccess, account]);

  const handleChangeTheme = () => {
    if (userTheme === THEME_MODE.DARK) {
      dispatch(setTheme(THEME_MODE.LIGHT));
      setStorageTheme(THEME_MODE.LIGHT.toLocaleLowerCase());
    } else {
      dispatch(setTheme(THEME_MODE.DARK));
      setStorageTheme(THEME_MODE.DARK.toLocaleLowerCase());
    }
  };

  const langArr = useMemo(() => {
    return Object.keys(languages)
      ?.map((item: any) => item)
      ?.map((lang: any) => ({ langCode: lang, value: lang, ...languages[lang] }));
  }, []);

  const handleChangeLanguage = (item: any) => {
    // setLanguage(item);
    setStorageLanguage(item);
    i18n.changeLanguage(item);
  };

  const renderRightMenuAction = () => {
    return (
      <div className="menu-language-theme">
        <div className="change-language">
          <FracSelectWithLanguage
            className="frac-select-language"
            value={languages[i18n.resolvedLanguage || LANGUAGE_CODE.ENGLISH]?.label}
            code={i18n.resolvedLanguage}
            options={langArr}
            onChange={handleChangeLanguage}
            zindexicon={1}
          />
        </div>
        <span className="change-theme">
          <img
            className="cursor-pointer"
            src={userTheme === THEME_MODE.DARK ? LightThemeIcon : DarkThemeIcon}
            alt="icon"
            onClick={handleChangeTheme}
          />
        </span>
      </div>
    );
  };

  const handleClickLogo = () => {
    window.open(process.env.REACT_APP_API_ENDPOINT_IAO, '_blank');
  };

  return (
    <Header className={`site-layout-header ${userTheme}`}>
      <div className="sidebar-wrapper">
        <div className="logo-section" onClick={() => handleClickLogo()}>
          <img src={userTheme === THEME_MODE.DARK ? FracLogoIconDarkVer2 : FracLogoIconVer2} alt="frac logo" />
        </div>
        <div className="sub-menu-container">{(isDesktop || width > 1024) && <SubMenu />}</div>
      </div>

      {(!isAuth || !account) && (
        <div className="info-section">
          <ButtonOutLined
            className="text-box wallet-address mobile-none"
            onClick={() => {
              setIsOpenConnectWalletModal(true);
            }}
          >
            {t('layout.header.menu.connectWallet')}
          </ButtonOutLined>
          {/* <Dropdown
            className="setting"
            trigger={['hover']}
            overlay={<Content isOpenDropdownWrapper={isOpenSetting} />}
            placement="topRight"
            onOpenChange={(boolean: boolean) => {
              setIsOpenSetting(boolean);
            }}
            overlayClassName="dropdown-wrapper"
          >
            <img className="setting-icon-no-auth" src={SettingIcon} alt="icon-setting" />
          </Dropdown> */}
          {(isDesktop || width > 1024) && renderRightMenuAction()}
          {!isDesktop && (
            <span
              className="menu-icon-mobile"
              onClick={() => {
                setIsOpenMenuMobile(!isOpenMenuMobile);
              }}
            >
              <MenuIcon color={'#878787'} isOpen={isOpenMenuMobile} />
            </span>
          )}
        </div>
      )}

      {userLoginSuccess && (
        <div className="info-section">
          <div className={`text-box network ${networkIsWrong ? 'warning-network' : ''}`}>
            <img src={NetworkIcon} alt="icon" />
            <span>{networkIsWrong ? t('layout.header.menu.wrongNetwork') : REACT_APP_NETWORK_NAME}</span>
          </div>

          {isDesktop && (
            <Dropdown
              open={isOpenNotify}
              trigger={['hover']}
              onOpenChange={(isOpen: boolean) => {
                setIsOpenNotify(isOpen);
              }}
              overlay={
                <NotificationContent
                  count={notificationCount}
                  setNotifyDetail={(data: any) => {
                    setNotifyDetail(data);
                    setIsOpenNotify(false);
                  }}
                  getCountUnRead={getCountUnRead}
                />
              }
              placement="bottomRight"
              overlayClassName="dropdown-wrapper"
            >
              <Badge
                className={`badge ${notificationCount < 1 ? '' : 'mr-28'} ${badgeCountClassName(
                  notificationCount,
                )}`}
                count={notificationCount}
                size="small"
              >
                <Icon component={AlertIconComponent} />
              </Badge>
            </Dropdown>
          )}

          {!isDesktop && (
            <Badge
              className={`badge ${notificationCount < 1 ? '' : 'mr-28'} ${badgeCountClassName(
                notificationCount,
              )}`}
              count={notificationCount}
              size="small"
            >
              <Icon
                component={AlertIconComponent}
                onClick={() => setIsOpenNotificationMobile(!isOpenNotificationMobile)}
              />
            </Badge>
          )}

          <WalletDetail address={userLoginSuccess ? (account as string) : ''}>
            <Icon className="highlight-icon mobile-none" component={WalletIconComponent} />
          </WalletDetail>

          <ProfileDetail>
            <Icon className="highlight-icon mobile-none" component={ProfileIconComponent} />
          </ProfileDetail>

          {(isDesktop || width > 1024) && renderRightMenuAction()}

          {/* {myTier?.tier && (
            <div
              className="tier-wrapper mobile-none"
              onClick={() => location.pathname !== PATHS.myTier() && history.push(PATHS.myTier())}
            >
              <span style={{ color: pickTierColor(myTier.id, userTheme === THEME_MODE.DARK) }}>
                {myTier.tier}
              </span>
            </div>
          )} */}

          {!isDesktop && (
            <span
              className="menu-icon-mobile"
              onClick={() => {
                setIsOpenMenuMobile(!isOpenMenuMobile);
              }}
            >
              <MenuIcon color={'#878787'} isOpen={isOpenMenuMobile} />
            </span>
          )}
        </div>
      )}

      <ConnectWalletModal
        open={isOpenConnectWalletModal}
        onCancel={() => setIsOpenConnectWalletModal(false)}
        closeModal={() => setIsOpenConnectWalletModal(false)}
        setIsOpenDeactivatedModal={setIsOpenDeactivatedModal}
      />

      {notifyDetail && (
        <ModalNotificationDetail
          isOpen={!!notifyDetail}
          onClose={() => setNotifyDetail(null)}
          notifyDetail={notifyDetail}
        />
      )}

      <DeactivatedModal
        open={isOpenDeactivatedModal}
        onCancel={() => {
          setIsOpenDeactivatedModal(false);
        }}
      />
    </Header>
  );
};

export default React.memo(AppHeader);
