import { trade } from '../constants/trade';

const initialState = {
  trades: [],
  interval: '',
  importingTokenList: [],
};

export const tradeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case trade.GET_LIST_TRADES: {
      return {
        ...state,
        trades: action.payload,
      };
    }

    case trade.SET_INTERVAL_TRADE: {
      return {
        ...state,
        trades: action.payload,
      };
    }

    case trade.SET_IMPORTING_TOKEN_LIST: {
      return {
        ...state,
        importingTokenList: [...state.importingTokenList, action.payload],
      };
    }

    case trade.CLEAR_IMPORTING_TOKEN_LIST: {
      return {
        ...state,
        importingTokenList: [],
      };
    }

    default: {
      return state;
    }
  }
};
